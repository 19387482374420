import { createAsyncThunk } from "@reduxjs/toolkit";

import { getConfigInspectionAsync } from "../../api/inspection";

export const getConfigInspection = createAsyncThunk(
  "configInspection/getConfigInspection",
  async (dataParams, state) => {
    try {
      const data = await getConfigInspectionAsync( dataParams.idFormato );
        if(data.body===null){
            throw new Error("Vacio");
        }
      return data;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);
