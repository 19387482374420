import React from "react";
import { Route, Routes } from "react-router-dom";

import AddFormat from "./dashboard/pages/addFormat/AddFormat";
import Dashboard from "./dashboard/Dashboard";

import ConfigInspection from "./dashboard/pages/configInspection/ConfigInspection";

import TemplateTray from "./dashboard/pages/templateTray/TemplateTray";
import Technical from "./dashboard/pages/technical/Technical";

import TrayInspection from "./dashboard/pages/trayInspection/TrayInspection";
import PlanificationInspection from "./dashboard/pages/planificationInspection/PlanifiationInspection";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";
import TokenAspired from "./pages/tokenAspired/TokenAspired";
import Welcome from "./dashboard/pages/welcome/Welcome";
import "./App.css";
import Reports from "./dashboard/pages/reports/Reports";

function App() {
  return (
    <Routes>
      <Route path={"/dashboard/:id"} element={<Dashboard />}>
        <Route index element={<Welcome />} />
      </Route>
      <Route path="/dashboard" element={<Dashboard />}>
        <Route path="inspections/config" element={<ConfigInspection />} />
        <Route path="inspections/tray" element={<TrayInspection />} />
        <Route path="inspections/format" element={<AddFormat />} />
        <Route path="inspections/format/:idFormat" element={<AddFormat />} />
        <Route path="technical/:idFormato" element={<Technical />} />
        <Route path="technical" element={<Technical />} />
        <Route path="inspections/templateTray" element={<TemplateTray />} />
        <Route path="inspections/reports" element={<Reports />} />
        <Route
          path="inspections/planification"
          element={<PlanificationInspection />}
        />
        {/*<Route path="inspections/calendar" element={<CalendarInspection />} />*/}
        <Route index element={<Welcome />} />
      </Route>
      <Route path={""} element={<Login />} />
      <Route path="tokenAspired" element={<TokenAspired />} />
      <Route path="/" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
