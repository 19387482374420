import { configureStore } from "@reduxjs/toolkit";
import formatReducer from "./formatReducer/formatoReducer.reducer";
import authReducer from "./authReducer/authReducer.reducer";
import technicalFormatReducer from "./technicalFormatoReducer/technicalFormatReducer.reducer";
import registerInspectReducer from "./registerInspectReducer/registerInspectReducer.reducer";
import planificationInspectionReducer from "./planificationInspectionReducer/planificationInspectionReducer.reducer";
import menuGroupReducer from "./menuGroupReducer/menuGroupReducer.reducer";
import trayInspectionReducer from "./trayInspectionReducer/TrayInspectionReducer.reducer";
import configInspectionReducer from "./configInspectionReducer/ConfigInspectionReducer.reducer";

export const store = configureStore({
  reducer: {
    formato: formatReducer,
    auth: authReducer,
    technicalFormato: technicalFormatReducer,
    registerInspect: registerInspectReducer,
    planificationInspect: planificationInspectionReducer,
    menuGroup: menuGroupReducer,
    trayInspection: trayInspectionReducer,
    configInspection:configInspectionReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});










