import MaterialReactTable from "material-react-table";
import { useMemo } from "react";
import { esES } from "@mui/material/locale";
//import { createTheme, ThemeProvider } from "@mui/material";
//import { useTheme } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

import "./AdaptedTable.css";

//import PersonAddSharpIcon from "@mui/icons-material/PersonAddSharp";

 

const AdaptedTable = ({
  columns,
  data,
  defaultColumn,
  loading,
  renderRowActionMenuItems,
  enableRowSelection,
  renderTopToolbarCustomActions,
  styleTable,
  renderDetailPanelTable,
  setGlobalFilter,
   
  globalFilter,
  columnFilters,
  setColumnFilters 
}) => {
  // modific color row if offline true or false
  const theme = useTheme();

  return (
    <div
      style={{
        width: `${styleTable ? "" : "90vw"} `,
        margin: "auto",
      }}
      className="adaptedTable"
    >
      <ThemeProvider theme={createTheme(theme, esES)}>
        <MaterialReactTable
            manualFiltering={true}
            onGlobalFilterChange={setGlobalFilter}
          
            onColumnFiltersChange={setColumnFilters}
           
          columns={columns}
          enableFullScreenToggle={false}
          muiTableProps={{
            sx: {
              tableLayout: "fixed",
            },
          }}
     
          data={data}
          enableRowSelection={enableRowSelection}
          localization={{
            actions: "Acciones",
            cancel: "Cancelar",
            changeFilterMode: "Cambia el modo de filtro",
            clearFilter: "Filtro claro",
            clearSearch: "Borrar búsqueda",
            clearSort: "Ordenar claro",
            columnActions: "Acciones de columna",
            edit: "Editar",
            expand: "Expandir",
            expandAll: "Expandir todo",
            filterByColumn: "Filtrar por {column}",
            filterMode: "Modo de filtro: {filterType}",
            grab: "Agarrar",
            groupByColumn: "Agrupar por {column}",
            groupedBy: "Agrupados por ",
            hideAll: "Ocultar todo",
            hideColumn: "Ocultar columna de {column}",
            rowActions: "Acciones de fila",
            pinToLeft: "Alfile a la izquierda",
            pinToRight: "Alfile a la derecha",
            save: "Salvar",
            search: "Búsqueda",
            selectedCountOfRowCountRowsSelected:
              "{selectedCount} de {rowCount} fila(s) seleccionadas",
            showAll: "Mostrar todo",
            showAllColumns: "Mostrar todas las columnas",
            showHideColumns: "Mostrar/Ocultar columnas",
            showHideFilters: "Alternar filtros",
            showHideSearch: "Alternar búsqueda",
            sortByColumnAsc: "Ordenar por {column} ascendente",
            sortByColumnDesc: "Ordenar por {column} descendiendo",
            thenBy: ", entonces por ",
            toggleDensity: "Alternar relleno denso",
            toggleFullScreen: "Alternar pantalla completa",
            toggleSelectAll: "Seleccionar todo",
            toggleSelectRow: "Seleccionar fila",
            ungroupByColumn: "Desagrupar por {column}",
            unpin: "Quitar pasador",
            unsorted: "Sin clasificar",
          }}
          
          defaultColumn={defaultColumn}
          state={{ isLoading: loading ,globalFilter: globalFilter, columnFilters: columnFilters}}
          enableRowActions={renderRowActionMenuItems ? true : false}
          renderRowActionMenuItems={renderRowActionMenuItems}
          renderTopToolbarCustomActions={renderTopToolbarCustomActions}
          renderDetailPanel={renderDetailPanelTable}
        

          /*sx: {
            //stripe the rows, make odd rows a darker color
            '& td:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5',
            },
          },
       
        if (original.offline === true) {
                return {
                  sx: {
                    //stripe the rows, make odd rows a darker color
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "rgba(216, 88, 0, 0.34)",
                    },
                  },
                };
              }

          /*initialState={{ columnVisibility: { firstName: false } }} */
        />
      </ThemeProvider>
    </div>
  );
};

export default AdaptedTable;
