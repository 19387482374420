import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressLoading from "../../../components/progressLoading/ProgressLoading";
import {
  refreshMenu,
  addId,
} from "../../../store/menuGroupReducer/menuGroupReducer.reducer";
import { Button, Paper, CardHeader, CardContent } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  getTechnicalFormat,
  addFechaInit,
} from "../../../store/technicalFormatoReducer/technicalFormatReducer.action";

import { getConfigInspection } from "../../../store/configInspectionReducer/ConfigInspectionReducer.action";
import { getTechnicalFormatOffline } from "../../../store/technicalFormatoReducer/technicalFormatReducer.reducer";
import SendModal from "./components/listFlat/components/sendModal/SendModal";
import SendModalAnular from "./components/listFlat/components/sendModal/SendModalAnular";

import {
  addStartTime,
  addStartDate,
} from "../../../store/technicalFormatoReducer/technicalFormatReducer.reducer";
import useNetwork from "../../../hooks/useNetwork/UseNetwork";
import "./Technical.css";
import ListFlat from "./components/listFlat/ListFlat";
import HeaderFlat from "./components/headerFlat/HeaderFlat";
import LoadingExport from "./components/listFlat/components/loadingExport/LoadingExport";

import { useIndexedDB } from "@slnsw/react-indexed-db";

const Technical = () => {
  const { getByIndex: getByIndexTech } = useIndexedDB("technicalFormatos");

  const [open, setOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [aux, setAux] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const networkState = useNetwork();
  const { online } = networkState;
  const [openModal, setOpenModal] = useState(false);
  const [openModalAnular, setOpenModalAnular] = useState(false);
  const { formato, loadingButton } = useSelector(
    (stateTecnical) => stateTecnical.technicalFormato
  );
  const { user, offlineStore } = useSelector((stateAuth) => stateAuth.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idFormato } = useParams();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const { loadingConfig, confingInspection, errorConfig } = useSelector(
    (stateConfig) => stateConfig.configInspection
  );

  const handleContinue = () => {
    setAux(true);
  };

  const handleClickAnular = async () => {
    setOpenModalAnular(!openModalAnular);
  };

  const handleInit = async () => {
    let date = new Date()
      .toLocaleString("sv-SE")
      .replace(" ", "T")
      .split(".")[0];

    await dispatch(addFechaInit({ date }));
    navigate(`/dashboard/technical/${idFormato}`);
    window.location.reload();
  };

  const handleCancel = () => {
    navigate("/dashboard/inspections/tray");
  };

  const handleModal = () => {
    if (formato?.fechaFin === null) {
      let date = new Date()
        .toLocaleString("sv-SE")
        .replace(" ", "T")
        .split(".")[0];

      dispatch(addStartDate(date));
    }
    if (formato?.horaFin === null) {
      let date = new Date()
        .toLocaleString("sv-SE")
        .replace(" ", "T")
        .split(".")[0];
      dispatch(addStartTime(date));
    }

    setOpenModal(!openModal);
  };

  //otros roles
  if (user?.ListRoles.find((e) => e === 18) === undefined && aux === false) {
    setAux(true);
  }
  //tecnico

  if (
    user?.ListRoles.find((e) => e === 18) &&
    formato?.estado === "ATENDIDA" &&
    aux === false
  ) {
    setAux(true);
  }

  useEffect(() => {
    if (idFormato === undefined) {
      return navigate("/dashboard/inspections/tray");
    }

    if (offlineStore === true) {
      dispatch(addId({ idConfig: state.id, idFormato: idFormato }));
      getByIndexTech("idConfig", state.id).then((res) => {
        dispatch(getTechnicalFormatOffline(res.formato));
      });

      dispatch(refreshMenu());
    } else {
    
      setIsloading(false);
      Promise.all([
        dispatch(getConfigInspection({ idFormato })),
        dispatch(getTechnicalFormat({ id: idFormato })),
        dispatch(refreshMenu()),
        dispatch(
          addId({
            idConfig: idFormato,
            idFormato: confingInspection?.idFormato,
          })
        ),
      ]).then( () => {setIsloading(true);});

      //dispatch(getConfigInspection({ idFormato }));
      //
      //dispatch(refreshMenu());
      // idFormat is id Config
    }
  }, [dispatch, online, offlineStore, idFormato, confingInspection?.id]);

  return (
    <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
      <HeaderFlat />
      <Paper
        square
        variant="outlined"
        elevation={1}
        sx={{ marginTop: "0.5rem" }}
      >
        <CardHeader
          sx={{ p: 0, m: 0 }}
          action={
            <>
              {!formato?.fechaInicioReal &&
              (formato?.estado !== "ATENDIDA" ||
                formato?.estado === "ANULADO") ? (
                <LoadingButton
                  loading={loadingButton}
                  variant="outlined"
                  onClick={async () => await handleInit()}
                  sx={{ mr: 2 }}
                  className="technical-initInspect"
                >
                  Iniciar
                </LoadingButton>
              ) : null}
              {user?.ListRoles.find((e) => e === 18) &&
                formato?.estado !== "ATENDIDA" && (
                  <div>
                    <Button
                      sx={{ ml: 2 }}
                      variant="outlined"
                      onClick={handleContinue}
                      disabled={aux}
                      className="technical-continueInspect"
                    >
                      Continuar
                    </Button>
                  </div>
                )}
              {formato?.estado === "ATENDIDA" ||
              formato?.estado === "ANULADO" ? null : (
                <Button
                  variant="contained"
                  onClick={handleModal}
                  disabled={
                    !formato?.fechaInicioReal ||
                    offlineStore === true ||
                    !online
                  }
                  sx={{ ml: 2 }}
                  className="technical-finishInspect"
                >
                  Finalizar Inspección
                </Button>
              )}
              {formato?.estado === "ATENDIDA" ||
              formato?.estado === "ANULADO" ? null : (
                <LoadingButton
                  color="error"
                  loading={loading}
                  onClick={handleClickAnular}
                  sx={{ ml: 2 }}
                  variant="contained"
                  className="technical-exportInspect"
                >
                  Anular
                </LoadingButton>
              )}
              {formato?.estado === "ATENDIDA" && <LoadingExport />}
              <Button
                color="error"
                variant="outlined"
                sx={{ m: 5 }}
                onClick={handleCancel}
                className="technical-cancelInspect"
              >
                Regresar
              </Button>{" "}
            </>
          }
        ></CardHeader>
      </Paper>

      {loading? <ProgressLoading />  : <ListFlat setOpen={setOpen} open={open} /> }

      <SendModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        open1={open1}
        setOpen1={setOpen1}
      />
      <SendModalAnular
        setOpenModalAnular={setOpenModalAnular}
        openModalAnular={openModalAnular}
        open2={open2}
        setOpen2={setOpen2}
      />
    </div>
  );
};

export default Technical;
