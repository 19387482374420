import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  clearFormat,
  clearErrorAction,
  clearSuccess,
} from "../../../../../../../store/technicalFormatoReducer/technicalFormatReducer.reducer";

import ProgressLoading from "../../../../../../../components/progressLoading/ProgressLoading";
import FooterModal from "./components/footerModal/FooterModal";
import SendButton from "./components/sendButton/SendButton";

import { Modal } from "@mui/material";
import { useEffect } from "react";

const SendModal = ({ setOpenModal, openModal, open1, setOpen1 }) => {
  const { loadingFormat, success, error } = useSelector(
    (state) => state.technicalFormato
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(clearFormat());
    navigate("/dashboard/inspections/tray");
  };
  useEffect(() => {
    if (error) {
      setOpen1(true);
      setTimeout(() => {
        setOpen1(false);
        setOpenModal(false);
        dispatch(clearErrorAction());
      }, 2500);
    }
    if (success) {
      setOpen1(true);
      setTimeout(() => {
        setOpen1(false);
        dispatch(clearSuccess());
        navigate("/dashboard/inspections/tray"); //redirect to ...
      }, 2500);
    }
  }, [error, success]);

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(!openModal)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="content-modal_sendFormat">
        {loadingFormat ? (
          <ProgressLoading />
        ) : open1 ? (
          <div className="button-format_modal">
            <h2 id="parent-modal-title">
              {error || "Finalizado  correctamente"}
            </h2>
            {error ? (
              <SendButton eventClick={() => setOpenModal(!openModal)} />
            ) : (
              <SendButton eventClick={handleNavigate} />
            )}
          </div>
        ) : (
          <FooterModal handleCloseModal={() => setOpenModal(!openModal)} />
        )}
      </div>
    </Modal>
  );
};

export default SendModal;
