import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import "./CustomAlert.css";

export default function CustomAlert({ tipe, message, handleClose }) {

  const adaptedMessage=(tipe)=>{
    if(tipe==="error"){
      return "Error"
    }
    if(tipe==="success"){
      return "Éxito"
    }
    if(tipe==="info"){
      return "Información"
    }

  }

  return (
    <Alert
      severity={tipe}
      onClose={handleClose}
      sx={{
        position: "fixed",
        bottom: "1rem",
        right: "2rem",
        width: "20rem",
        zIndex: "999",
      }}
      className="customAlert-alert"
    >
      <AlertTitle>{adaptedMessage(tipe)}</AlertTitle>
      {message}
    </Alert>
  );
}
