import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  clearFormat,
  clearErrorAction,
  clearSuccess,
} from "../../../../../store/formatReducer/formatoReducer.reducer";
import ProgressLoading from "../../../../../components/progressLoading/ProgressLoading";
import FooterModal from "./footerModal/FooterModal";
import SendButton from "../sendButton/SendButton";

import { Modal } from "@mui/material";
import { useEffect } from "react";

const SendModal = ({ setOpenModal1, openModal1, open1, setOpen1 }) => {
  const { loadingFormat, success, error } = useSelector(
    (state) => state.formato
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(clearFormat());
    navigate("/dashboard/inspections/templateTray");
  };
  useEffect(() => {
    if (error) {
      setOpen1(true);
      setTimeout(() => {
        setOpen1(false);
        setOpenModal1(false);
        dispatch(clearErrorAction());
      }, 2500);
    }
    if (success) {
      setOpen1(true);
      setTimeout(() => {
        setOpen1(false);
        dispatch(clearSuccess());
        navigate("/dashboard/inspections/templateTray");
      }, 2500);
    }
  }, [error, success]);

  return (
    <Modal
      open={openModal1}
      onClose={() => setOpenModal1(!openModal1)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="content-modal_sendFormat">
        {loadingFormat ? (
          <ProgressLoading />
        ) : open1 ? (
          <div className="button-format_modal">
            <h2 id="parent-modal-title">
              {error || "Formato Creado con éxito"}
            </h2>
            {error ? (
              <SendButton eventClick={() => setOpenModal1(!openModal1)} />
            ) : (
              <SendButton eventClick={handleNavigate} />
            )}
          </div>
        ) : (
          <FooterModal handleCloseModal={() => setOpenModal1(!openModal1)} />
        )}
      </div>
    </Modal>
  );
};

export default SendModal;
