import ax from "../../helpers/axiosConfig";

export const postmenuGroupAsync = (data) => {
  const { grupoData } = data;

  return ax.post(`/api/RegistroInspeccion/registroInspeccion`, grupoData);
};

export const postObtenerMenuAsync = (data) => {
  return ax.post(`/api/RegistroInspeccion/obtenerRegistros`, data);
};

export const getParametrosAsync = (data) => {
  const { idGrupo,fechaGrupo } = data;
  return ax.get(`/api/RegistroInspeccion/obtenerRegistro/${idGrupo}/${fechaGrupo}`);
};

export const updateGroupAsync = (data) => {
  const { idGrupo } = data;

  return ax.put(`/api/RegistroInspeccion/actualizarRegistros/${idGrupo}`, data);
};

export const actualizarParamsAsync = (id) => {
  return ax.put(`/api/RegistroInspeccion/actualizarParametro/${id}`);
};
