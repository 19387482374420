import { useState, useEffect } from "react";

import { getMyClient } from "../../../api/ediServices";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  restoreMyClient,
  addMyclient,
} from "../../../store/formatReducer/formatoReducer.reducer";
import {
  InputLabel,
  Autocomplete,
  TextField,
  CircularProgress,
  Grid,
} from "@mui/material";

import "./MyClient.css";

const MyClient = () => {
  const dispatch = useDispatch();
  const { formato } = useSelector((state) => state.formato);

  const [Arrclient, setArrClient] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const clientstorage = localStorage.getItem("jsonCliente");

  const refreshPage = (id, label) => {
    localStorage.setItem("jsonCliente", JSON.stringify({ id, label }));

    navigate(0);
  };

  useEffect(() => {
    (async () => {
      try {
        const idclienteSession = JSON.parse(localStorage.getItem("accessEdi"));
        const res = await getMyClient();
        if (!clientstorage) {
          const entidadcliente = JSON.parse(res);

          const result = entidadcliente.ListaEntidadComun.filter(
            (clientesession) => clientesession.Id === idclienteSession.IdCliente
          );

          const objtUsuarioSession = { id: result[0].Id, label: result[0].Nombre };

          localStorage.setItem(
            "jsonCliente",
            JSON.stringify(objtUsuarioSession)
          );
        }

        setLoading(true);

        const { ListaEntidadComun } = JSON.parse(res);

        setArrClient(ListaEntidadComun);
        dispatch(addMyclient(JSON.parse(localStorage.getItem("jsonCliente"))));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch]);
  return (
    <Grid container spacing={0}>
      <Grid xs={1}>
        <InputLabel id="clientsInspection" className="myclient-label">
          Cliente :
        </InputLabel>
      </Grid>
      <Autocomplete
        className="myclient-input"
        size="small"
        disablePortal
        value={
          formato?.cliente ? JSON.stringify(formato?.cliente) : clientstorage
        }
        id="clientsInspection1"
        options={
          Arrclient
            ? Arrclient.map((e) =>
                JSON.stringify({ label: e.Nombre, id: e.Id })
              )
            : []
        }
        getOptionLabel={(option) => {
          let data = JSON.parse(option);
          return data.label;
        }}
        onChange={(event, newValue) => {
          if (newValue === null) {
            dispatch(restoreMyClient());
          } else {
            const { id, label } = JSON.parse(newValue);

            dispatch(addMyclient({ id, label }));
            refreshPage(id, label);
          }
        }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Mis Clientes"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
};

export default MyClient;
