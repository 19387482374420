import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inmuebles: null,

  error: null,
  loading: false,
  success: false,
};

export const planificationInspectionReducer = createSlice({
  name: "planificationInspectionReducer",
  initialState,
  reducers: {
    addInmuebles: (state, action) => {
      const data = action.payload.map((item) => JSON.parse(item));
      state.inmuebles = data;
    },
  },
});

export const { addInmuebles } = planificationInspectionReducer.actions;
export default planificationInspectionReducer.reducer;


