import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import MaterialReactTable from "material-react-table";
import { esES } from "@mui/material/locale";
import { createTheme, Select, ThemeProvider, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField, MenuItem, InputLabel, FormControl } from "@mui/material";

const frecuency = [
  "ANUAL",
  "BIMENSUAL",
  "DIARIA",
  "QUINCENAL",
  "SEMANAL",
  "SEMESTRAL",
  "TRIMESTRAL",
];
const duration = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const RangeDateTime = () => {
  const { inmuebles } = useSelector((state) => state.planificationInspect);

  const theme = useTheme();
  const columns = useMemo(
    () => [
      {
        accessorKey: "name.firstName", //access nested data with dot notation
        header: "Código",
      },
      {
        accessorKey: "name.lastName",
        header: "Uso Genérico",
      },
      {
        accessorKey: "inmueble", //normal accessorKey
        header: "Inmueble",
      },
      {
        accessorKey: "address",
        header: "Dirección",
      },
      {
        accessorKey: "fechaInicio",
        header: "Fecha Inicio",

        columnDefType: "display", //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Cell: ({ row }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Fecha Inicio"
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "fechaFin",
        header: "Fecha Fin",
        columnDefType: "display", //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Cell: ({ row }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Fecha Fin"
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "frecuencia",
        header: "Frecuencia",
        columnDefType: "display", //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Cell: ({ row }) => (
          <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
            <InputLabel id="frecuencia">Seleccione</InputLabel>

            <Select
              InputLabelProps={{ shrink: true }}
              label="Seleccione"
              labelId="frecuencia"
            >
              <MenuItem disabled selected>
                Seleccionar...
              </MenuItem>

              {frecuency.map((el, index) => (
                <MenuItem value={el} key={index + "frecuency"}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      },
      {
        accessorKey: "duracion",
        header: "Duración",
        columnDefType: "display", //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Cell: ({ row }) => (
          <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
            <InputLabel id="frecuencia">Seleccione</InputLabel>

            <Select
              InputLabelProps={{ shrink: true }}
              label="Seleccione"
              labelId="frecuencia"
            >
              <MenuItem disabled selected>
                Seleccionar...
              </MenuItem>

              {duration.map((el, index) => (
                <MenuItem value={el} key={index + "duration"}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      },
      {
        accessorKey: "cliente",
        header: "Cliente",
      },
    ],
    []
  );
  return (
    <ThemeProvider theme={createTheme(theme, esES)}>
      <MaterialReactTable
        columns={columns}
        data={inmuebles}
        localization={{
          actions: "Acciones",
          cancel: "Cancelar",
          changeFilterMode: "Cambia el modo de filtro",
          clearFilter: "Filtro claro",
          clearSearch: "Borrar búsqueda",
          clearSort: "Ordenar claro",
          columnActions: "Acciones de columna",
          edit: "Editar",
          expand: "Expandir",
          expandAll: "Expandir todo",
          filterByColumn: "Filtrar por {column}",
          filterMode: "Modo de filtro: {filterType}",
          grab: "Agarrar",
          groupByColumn: "Agrupar por {column}",
          groupedBy: "Agrupados por ",
          hideAll: "Ocultar todo",
          hideColumn: "Ocultar columna de {column}",
          rowActions: "Acciones de fila",
          pinToLeft: "Alfile a la izquierda",
          pinToRight: "Alfile a la derecha",
          save: "Salvar",
          search: "Búsqueda",
          selectedCountOfRowCountRowsSelected:
            "{selectedCount} de {rowCount} fila(s) seleccionadas",
          showAll: "Mostrar todo",
          showAllColumns: "Mostrar todas las columnas",
          showHideColumns: "Mostrar/Ocultar columnas",
          showHideFilters: "Alternar filtros",
          showHideSearch: "Alternar búsqueda",
          sortByColumnAsc: "Ordenar por {column} ascendente",
          sortByColumnDesc: "Ordenar por {column} descendiendo",
          thenBy: ", entonces por ",
          toggleDensity: "Alternar relleno denso",
          toggleFullScreen: "Alternar pantalla completa",
          toggleSelectAll: "Seleccionar todo",
          toggleSelectRow: "Seleccionar fila",
          ungroupByColumn: "Desagrupar por {column}",
          unpin: "Quitar pasador",
          unsorted: "Sin clasificar",
        }}
      />
      ;
    </ThemeProvider>
  );
};

export default RangeDateTime;
