import ax from "../../helpers/axiosConfig";
import moment from "moment";

export const postRegisterInspectionAsync = (data) => {
  const {
    cliente,
    inmueble,
    plantilla,
    proveedor,
    tecnicos,
    inspectoresFm,
    responsable,
    startDate,
    startTime,
    tipoInspeccion,
    tecnicoResponsable,
  } = data;

  const Obj = {};
  Obj.idCliente = cliente.id;
  Obj.clienteNombre = cliente.label;
  Obj.inmuebles = inmueble;
  Obj.idFormato = plantilla.id;
  Obj.idProveedor = proveedor.id;
  Obj.proveedorNombre = proveedor.nombre;
  Obj.idResponsable = responsable.id;
  Obj.responsableNombre = responsable.label;
  Obj.tecnicos = tecnicos.map((e) => e.id);
  Obj.idtecnicoResponsable = tecnicoResponsable.id;
  Obj.tecnicoResponsableNombre = tecnicoResponsable.label;
  Obj.inspectoresFm = inspectoresFm.map((e) => e.id);
  Obj.fechaInicio = startDate;
  Obj.horaInicio = startTime;
  Obj.SkeyDetEstado = "ESTADO0000086.22"; //en programación default
  Obj.tipoInspeccion = tipoInspeccion;
  Obj.idUsuario = JSON.parse(localStorage.getItem("accessEdi")).Id;
  return ax.post(`/api/ConfigInspecciones/GuardarConfigInspecciones`, Obj);
};

export const getInspectionAsync = (data) => {
  const { id, idCliente ,filtroText,filtroCodigo,filtroInmueble,filtroResponsable,filtroFormato,filtroEstado,filtroOffline} = data;
  return ax.post(`/api/ConfigInspecciones/ObtenerConfigInspecciones`, {
    filter: { usuario: id, idCliente:idCliente, filtroText,filtroCodigo,filtroInmueble,filtroResponsable,filtroFormato,filtroEstado,filtroOffline},
    page: 0,
    pageSize: 1000,
  });
};


export const offline = (idConfig) => {
  return ax.get(`/api/Inspecciones/Offline/${idConfig}`);
};

export const postAnularAsync = (idConfig) => {
  return ax.get(`/api/Inspecciones/Anular/${idConfig}`);
};

export const liberarOffline = (idConfig) => {
  return ax.get(`/api/Inspecciones/LiberarOffline/${idConfig}`);
};

export const getConfigInspectionAsync = (idConfig) => {
  return ax.get(`/api/ConfigInspecciones/ObtenerInspeccion/${idConfig}`);
};
