import React, { useEffect, useState } from "react";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";

import { useIndexedDB } from "@slnsw/react-indexed-db";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {

  offlineState,
} from "../../../store/authReducer/authReducer.reducer";

import useNetwork from "../../../hooks/useNetwork/UseNetwork";

const OfflineSwitch = ({ menu }) => {
  const dispatch = useDispatch();
  const networkState = useNetwork();
  const { online } = networkState;
  const [checkedUser, setCheckedUser] = useState(false);

  const { update: updateMenu } = useIndexedDB("Menus");
  const { getByIndex, update: updateUser } = useIndexedDB("Usuarios");
  const { user } = useSelector((state) => state.auth);

  const accessEdi = JSON.parse(localStorage.getItem("accessEdi"));
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    (async () => {
      try {
        if (user?.countHourMinute) {
          const res = await getByIndex("idUsuario", user?.countHourMinute);

          if (res !== undefined) setCheckedUser(res.offline);
        }
      } catch (e) {
        setCheckedUser(false);
        console.log(e);
      }
    })();
  }, [user?.countHourMinute]);
  useEffect(() => {
    if (!online) {
      (async () => {
        setCheckedUser(true);
        const data = await getByIndex("idUsuario", user?.countHourMinute);

        let menuOffline = menu; //inmutable junto a sus propiedades
        menuOffline = menuOffline?.map((item) => {
          const newItem = { ...item };
          if (newItem.id === 9124) {
            let objNew = [...newItem.subMenu].find((item) => {
              const newEl = { ...item };
              if (newEl.id === 9131) {
                return newEl;
              }
            });

            newItem.subMenu = [objNew];
          }
          return newItem;
        });

        if (data) {
          await updateMenu({
            id: data.id,
            menu: menuOffline,
            idUsuario: user?.countHourMinute,
            estado: 20,
            activo: true,
            offline: true,
          });

          await updateUser({
            id: data.id,
            idUsuario: user?.countHourMinute,
            accessEdi: accessEdi,
            accesToken: accessToken,
            datos: user,
            estado: 1,
            activo: true,
            offline: true,
          });
          dispatch(offlineState(true));
        }
      })();
    }
  }, [online]);

  const handleCheck = async (e) => {
    setCheckedUser(e.target.checked);
    const data = await getByIndex("idUsuario", user?.countHourMinute);

    let menuOffline = menu; //inmutable junto a sus propiedades
    menuOffline = menuOffline?.map((item) => {
      const newItem = { ...item };
      if (newItem.id === 9124) {
        let objNew = [...newItem.subMenu].find((item) => {
          const newEl = { ...item };
          if (newEl.id === 9131) {
            return newEl;
          }
        });

        newItem.subMenu = [objNew];
      }
      return newItem;
    });

    if ((data && e.target.checked) || checkedUser) {
      await updateMenu({
        id: data.id,
        menu: menuOffline,
        idUsuario: user?.countHourMinute,
        estado: 20,
        activo: true,
        offline: true,
      });

      await updateUser({
        id: data.id,
        idUsuario: user?.countHourMinute,
        accessEdi: accessEdi,
        accesToken: accessToken,
        datos: user,
        estado: 1,
        activo: true,
        offline: true,
      });
      dispatch(offlineState(true));
    }
    if (e.target.checked === false) {
      await updateMenu({
        id: data.id,
        menu: menuOffline,
        idUsuario: user?.countHourMinute,
        estado: 1,
        activo: true,
        offline: false,
      });
      await updateUser({
        id: data.id,
        idUsuario: user?.countHourMinute,
        accessEdi: accessEdi,
        accesToken: accessToken,
        datos: user,
        estado: 1,
        activo: true,
        offline: false,
      });
      dispatch(offlineState(false));
    }
  };

  return (
    <FormGroup className="input-group">
      <FormControlLabel
        className="switch-label"
        label="Offline"
        control={
          <Switch
           
            onChange={async (e) => await handleCheck(e)}
        
          checked={checkedUser}
          />
        }
        sx={{ color: "blue" }}
      />
    </FormGroup>
  );
};;

export default OfflineSwitch;
