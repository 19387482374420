import { Link } from "react-router-dom";
import "../pages.css";

const NotFound = () => {
  /*t3.fromTo(
    ".wrong-para",
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 1,
      stagger: {
        repeat: -1,
        yoyo: true,
      },
    }*/

  return (
    <div className="notfoundContent">
      <div className="notfound">
        <div className="notfound-bg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>oops!</h1>
        <h2>Error 404 : Página no encontrada</h2>
        <Link to="/">Regresar</Link>
      </div>
    </div>
  );
};

export default NotFound;
