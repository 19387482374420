export const content_paper = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  width: "80%",
  maxWidth: "20rem",
  minHeight: "3rem",
  marginLeft: "1rem",
  listStyle: "none",
  maxHeight: "6rem",
  overflowY: "scroll",
  marginTop: "1rem",
  padding: 0.5,
};

export const content_add = {
  display: "flex",
  justifyContent: "space-around",
  marginTop: "1.5rem",
  paddingLeft: "1rem",
};
