import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";

import Inmuebles from "./components/inmuebles/Inmuebles";
import RangeDateTime from "./components/rangeDateTime/RangeDateTime";
import Planification from "./components/planification/Planification";
//import Planification from "./components/planification/Planification";

const steps = [
  "Seleccionar Inmueble",
  "Seleccionar Inspector y rango de fechas",
  "Planificar Inspección",
];

export default function PlanificationInspection() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [aux, setAux] = useState(false);
  const dispatch = useDispatch();
  const { inmuebles } = useSelector((state) => state.planificationInspect);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    inmuebles?.length !== 0 ? setAux(true) : setAux(false);
  }, [inmuebles?.length]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1.5rem" }}>
        <Link underline="hover" color="inherit" to="/">
          Inspecciones
        </Link>
        <Typography color="text.primary">
          Planificación de Inspección
        </Typography>
      </Breadcrumbs>
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginBottom: "3rem" }}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            {activeStep === 0 && <Inmuebles />}
            {activeStep === 1 && <RangeDateTime />}
            {activeStep === 2 && <Planification />}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button onClick={handleNext} disabled={!aux} variant="contained">
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
