import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTemplate } from "../../../../../api/format";
import {
  getProveedorFiltro,
  getTecnicos,
  getInspectorFiltro,
} from "../../../../../api/ediServices";
import {
  addPlantilla,
  addProveedor,
  addTecnicos,
  addTipoInspeccion,
  addInspectoresFm,
  addResponsable,
  addTecResponsable
} from "../../../../../store/registerInspectReducer/registerInspectReducer.reducer";

import {
  FormHelperText,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material/";

const TechnicalSelect = () => {
  const dispatch = useDispatch();
  const { RegisterData } = useSelector((state) => state.registerInspect);

 
  const [formatList, setFormatList] = useState(null);
  const [proveedorList, setProveedorList] = useState(null);
  const [tecnicoList, setTecnicoList] = useState(null);
  const [inspectorList, setInspectorList] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [plantillaSelected, setPlantillaSelected] = useState(false);
  const [proveedorSelected, setProveedorSelected] = useState(false);
  const [tipoInspectorSelected, setTipoInspectorSelected] = useState(false);
  const [inspectorSelected, setInspectorSelected] = useState(false);
  const [responsableSelected, setResponsableSelected] = useState(false);
  const [tecresponsableSelected, setTecResponsableSelected] = useState(false);

  const validateParametrs = () => {
    if (!RegisterData?.plantilla) {
      setPlantillaSelected(true);
    }
    if (!RegisterData?.proveedor) {
      setProveedorSelected(true);
    }
    if (!RegisterData?.tipoInspeccion) {
      setTipoInspectorSelected(true);
    }
    if (!RegisterData?.inspectoresFm?.length === 0) {
      setInspectorSelected(true);
    }

    if (!RegisterData?.responsable) {
      setResponsableSelected(true);
    }
    if (!RegisterData?.tecnicos?.length === 0) {
      setTecResponsableSelected(true);
    }
  };

  const getFormatAsync = async () => {
    try {
      setLoading3(true);
  
      const { data } = await getTemplate(RegisterData?.cliente?.id);
      setFormatList(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading3(false);
    }
  };
  const getProveedorAsync = async () => {
    try {
      setLoading(true);
      const { ListaEntidadComun } = await getProveedorFiltro(
        RegisterData?.cliente?.id
      );

      setProveedorList(ListaEntidadComun);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getTecnicosAsync = async (prov) => {
    try {
      setLoading2(true);
      const data = {
        IdsCliente: RegisterData?.cliente?.id,
        IdsProveedor: prov?.id,
        Nombre: "",
      };

      const res = await getTecnicos(data);

      const { ListaUsuarios } = JSON.parse(res);
      setTecnicoList(ListaUsuarios);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading2(false);
    }
  };

  const getInspectorFiltroAsync = async () => {
    try {
      setLoading1(true);

      const res = await getInspectorFiltro(RegisterData?.cliente?.id);
      // console.log(res);
      // const data = JSON.parse(res);
      // console.log(data);
      setInspectorList(res);

    } catch (err) {
      console.log(err);
    } finally {
      setLoading1(false);
    }
  };

  const handleInspec = (e) => {
    setTipoInspectorSelected(false);
    dispatch(addTipoInspeccion({ inspection: e.target.value }));
  };

  const handleRespo = (e) => {
    setResponsableSelected(false);
    const { value } = e.target;
    dispatch(addResponsable(JSON.parse(value)));
  };
  const handleTecRespo = (e) => {
    setResponsableSelected(false);
    const { value } = e.target;
    dispatch(addTecResponsable(JSON.parse(value)));
  };

  useEffect(() => {
    getFormatAsync();
    getProveedorAsync();
    getInspectorFiltroAsync();
  }, [RegisterData?.cliente]);

  console.log(RegisterData?.cliente);
  return (
    <>
      <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
        <Autocomplete
          fullWidth
          value={
            RegisterData?.plantilla
              ? JSON.stringify(RegisterData?.plantilla)
              : null
          }
          options={
            formatList
              ? formatList.map((el, index) =>
                  JSON.stringify({
                    id: el.id,
                    codigo: el.codigo,
                    nombre: el.nombre,
                  })
                )
              : []
          }
          getOptionLabel={(option) => {
            let data = JSON.parse(option);
            return `${data.codigo} - ${data.nombre}`;
          }}
          loading={loading3}
          renderInput={(params) => (
            <TextField
              onBlur={validateParametrs}
              error={plantillaSelected}
              label="Plantillas"
              {...params}
              // error={RegisterData?.tecnicos.length === 0}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading3 ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              dispatch(addPlantilla(JSON.parse(newValue)));
              setPlantillaSelected(false);
            } 
          }}
        />
        {plantillaSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
        <Autocomplete
          fullWidth
          value={
            RegisterData?.proveedor
              ? JSON.stringify(RegisterData?.proveedor)
              : null
          }
          options={
            proveedorList
              ? proveedorList.map((el) =>
                  JSON.stringify({
                    id: el.Id,
                    nombre: el.Nombre,
                    tipo: el.Tipo,
                  })
                )
              : []
          }
          getOptionLabel={(option) => {
            let data = JSON.parse(option);
            return data.nombre;
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              onBlur={validateParametrs}
              error={proveedorSelected}
              label="Proveedor"
              {...params}
              // error={RegisterData?.tecnicos.length === 0}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setProveedorSelected(false);

              dispatch(addProveedor(JSON.parse(newValue)));
              dispatch(addTecnicos([]));
              getTecnicosAsync(JSON.parse(newValue));
            }
          }}
        />
        {proveedorSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>

      <br />
      <br />

      <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
        <Autocomplete
          multiple={true}
          fullWidth
          value={
            RegisterData?.tecnicos?.length !== 0
              ? (RegisterData?.tecnicos).map((e) => JSON.stringify(e))
              : []
          }
          options={
            tecnicoList
              ? tecnicoList?.map((e) =>
                  JSON.stringify({ label: e.Nombre, id: e.Id })
                )
              : []
          }
          getOptionLabel={(option) => {
            let data = JSON.parse(option);
            return data.label;
          }}
          loading={loading2}
          renderInput={(params) => (
            <TextField
              label="Técnicos"
              {...params}
              // error={RegisterData?.tecnicos.length === 0}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading2 ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              dispatch(addTecnicos(newValue));
            }
          }}
          
        />
      </FormControl>
      <br />
      <br />
      <FormControl
        fullWidth
        sx={{ marginTop: "0.8rem" }}
        disabled={RegisterData?.tecnicos?.length === 0}
      >
        <InputLabel id="responsable">Técnico Responsable</InputLabel>
        <Select
          labelId="responsable"
          value={JSON.stringify(RegisterData?.tecnicoResponsable)}
          label="Responsable de inspeccion"
          onChange={handleTecRespo}
          error={tecresponsableSelected}
          onBlur={validateParametrs}
        >
          {RegisterData?.tecnicos?.map((e, index) => (
            <MenuItem
              value={JSON.stringify({ label: e.label, id: e.id })}
              key={index + "responsable"}
            >
              {e.label}
            </MenuItem>
          ))}
        </Select>
        {tecresponsableSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>
      <br />
      <br />

      <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
        <InputLabel id="tipoIns">Tipo de inspección</InputLabel>
        <Select
          labelId="tipoIns"
          label="Tipo de inspeccion"
          value={RegisterData?.tipoInspeccion}
          onBlur={validateParametrs}
          onChange={handleInspec}
          error={tipoInspectorSelected}
        >
          <MenuItem value="TECNICA">TECNICA</MenuItem>
          <MenuItem value="INDECI">INDECI</MenuItem>
          <MenuItem value="COMERCIAL">COMERCIAL</MenuItem>
        </Select>
        {tipoInspectorSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
        <Autocomplete
          multiple={true}
          id="inspectores"
          fullWidth
          value={
            RegisterData?.inspectoresFm?.length !== 0
              ? RegisterData?.inspectoresFm?.map((e) => JSON.stringify(e))
              : []
          }
          options={
            inspectorList
              ? inspectorList?.map((e) =>
                  JSON.stringify({
                    label: e.nombreInspector,
                    id: e.idInspector,
                  })
                )
              : []
          }
          getOptionLabel={(option) => {
            let data = JSON.parse(option);
            return data.label;
          }}
          loading={loading1}
          renderInput={(params) => (
            <TextField
              {...params}
              // error={RegisterData?.inspectoresFm?.length === 0}
              label="inspectores FM"
              error={inspectorSelected}
              onBlur={validateParametrs}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading1 ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setInspectorSelected(false);
              dispatch(addInspectoresFm(newValue));
            }
          }}
        />
        {inspectorSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>
      <br />
      <br />
      <FormControl
        fullWidth
        sx={{ marginTop: "0.8rem" }}
        disabled={RegisterData?.inspectoresFm?.length === 0}
      >
        <InputLabel id="responsable">Inspector Responsable</InputLabel>
        <Select
          labelId="responsable"
          value={JSON.stringify(RegisterData?.responsable)}
          label="Responsable de inspeccion"
          onChange={handleRespo}
          error={responsableSelected}
          onBlur={validateParametrs}
        >
          {RegisterData?.inspectoresFm?.map((e, index) => (
            <MenuItem
              value={JSON.stringify({ label: e.label, id: e.id })}
              key={index + "responsable"}
            >
              {e.label}
            </MenuItem>
          ))}
        </Select>
        {responsableSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default TechnicalSelect;
