import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../store/formatReducer/formatoReducer.reducer";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuItem, Menu, Button } from "@mui/material";
import SelectList from "../../../selectList/SelectList";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};
const MultiValues = ({ listName, indice, id }) => {
  const dispatch = useDispatch();

  const { formato } = useSelector((state) => state.formato);

  let propiedades =
    indice.indiceSubcampo === undefined
      ? formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams]
      : formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo];

  const {
    observacion,
    requiereCorrectivo,
requiereAdjuntos,
    fieldLabel,
    fieldPlaceholder,
    fieldMandatory,
    fieldEditable,
    fieldVisible,
    fieldColumn,
    fieldRow,
    fileDb,
  } = propiedades;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButton = () => {
    dispatch(
      addField({
        indiceSection: indice.indiceSection,
        indiceGroup: indice.indiceGroup,
        indiceParams: indice.indiceParams,
        indiceSubcampo: indice.indiceSubcampo,
        field: {
          indiceSection: indice.indiceSection,
          indiceGroup: indice.indiceGroup,
          indiceParams: indice.indiceParams,
          id,
          fileDb,
          idParametro: 9,
          field_id: "switchCheck",
          fieldLabel: fieldLabel,
          fieldMandatory: fieldMandatory,
          fieldEditable: fieldEditable,
          fieldVisible: fieldVisible,
          fieldColumn: fieldColumn,
          fieldRow: fieldRow,
          fieldPlaceholder: fieldPlaceholder,
          fieldType: "switchCheck",
          fieldValue: "false",
          observacion,
          requiereCorrectivo,
requiereAdjuntos,
          subCampos: [],
        },
      })
    );
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ width: "none", minWidth: "none" }}
      >
        {listName}
        {open ? <ArrowRightIcon /> : <ArrowDropUpIcon />}
      </MenuItem>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...popoverPropiert}
      >
        <SelectList name={"Lista"} indice={indice} id={id} multiple={false} />
        <SelectList
          name={"Lista Multiple"}
          indice={indice}
          id={id}
          multiple={true}
        />
        <MenuItem onClick={handleButton}>CheckBox</MenuItem>
      </Menu>
    </>
  );
};

export default MultiValues;
