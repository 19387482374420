import React, { useState } from "react";
//import { login } from "../../store/authReducer/authReducer.action";
//import { clearErrorAction } from "../../store/authReducer/authReducer.reducer";
//import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMyClient } from "../../api/ediServices";

import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./Login.css";
import { useEffect } from "react";
import ProgressLoading from "../../components/progressLoading/ProgressLoading";
import CustomAlert from "../../components/customAlert/CustomAlert";
import { postLoginAsync } from "../../api/auth";

const styleInput = {
  width: "100%",
  paddingBottom: "1.34375em",
};

const Login = () => {
  const [auth, setAuth] = useState({
    login: "",
    password: "",
    Origen: "1",
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const toBinary = (string) => {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    const charCodes = new Uint8Array(codeUnits.buffer);
    let result = "";
    for (let i = 0; i < charCodes.byteLength; i++) {
      result += String.fromCharCode(charCodes[i]);
    }
    return result;
  };

  const handleClick = async () => {
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("accessEdi");
    localStorage.clear();
    try {
      setLoading(true);
      const data = await postLoginAsync(auth);
      if (data.TipoResultado === 1) {
        const usuarioSesion = {
          Flagproveedor: data.Datos.Flagproveedor,
          Flagconfiguracionpersona: data.Datos.Flagconfiguracionpersona,
          Login: data.Datos.Login,
          Nombre: data.NombreCompleto,
          ApellidoPaterno: "",
          ApellidoMaterno: "",
          Email: data.Datos.Email,
          NumeroDocumento: data.NumeroDocumento,
          IdCliente: data.Ubicacion.IdCliente,
          PermisoPorDefecto: data.PermisoPorDefecto,
          IdTipoDocumento: data.IdTipoDocumento,
          Telefono: data.Datos.Telefono,
          Celular: data.Datos.Celular,
          Id: data.Id,
          FechaUltimoAcceso: data.FechaUltimoAcceso,
        };
        
        const request = {
          usuario: usuarioSesion,
          token: data.token,
        };
        const encriptdado = toBinary(JSON.stringify(request));
        const encoded = btoa(encriptdado);
        
        navigate(`Dashboard/${encoded}`);
      }
      if (data.TipoResultado === 2) {
        setOpen(true);
        setMessage(data.Mensaje);
      }
    } catch (err) {
      console.log(err);
      setOpen(true);
      setMessage(
        "Error al iniciar sesión / Comunicarse con el Area de soporte"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open]);

  return (
    <>
      {open && (
        <CustomAlert tipe="error" message={message} handleClose={handleClose} />
      )}
      <div className="auth">
        <div className="auth-form">
          <div className="auth-form__card">
            <div className="auth-form__card--logo">
              <figure className="logo">
                <img src="../images/auth/logo-tgestiona.png" alt="Tgestiona" />
              </figure>
            </div>
            <div className="auth-form__card--title">Iniciar sesión</div>

            <form className="form">
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={styleInput}
                type="text"
                label="Usuario"
                onChange={(e) => setAuth({ ...auth, login: e.target.value })}
              />

              <TextField
                id="outlined-basic"
                variant="outlined"
                style={styleInput}
                type="password"
                label="Contraseña"
                onChange={(e) => setAuth({ ...auth, password: e.target.value })}
              />
              {loading ? (
                <ProgressLoading />
              ) : (
                <Button
                  type="button"
                  className="form__button"
                  style={{
                    backgroundColor: "#156185",
                    borderRadius: "9999px",
                    color: "#fff",
                    marginTop: "1rem",
                  }}
                  onClick={async () => await handleClick()}
                >
                  Ingresar
                </Button>
              )}
              {/*<div className="alert-error mt-2">
              El usuario o contraseña no son correctos.
            </div>*/}
            </form>
          </div>
        </div>
        <div className="content-background">
          <svg
            className="semi-circle"
            viewBox="0 0 960 540"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              className=""
              fill="none"
              stroke="currentColor"
              strokeWidth="100"
              style={{
                color: "rgba(51, 65, 85, 1)",
                opacity: 0.25,
              }}
            >
              <circle r="234" cx="196" cy="23"></circle>
              <circle r="234" cx="790" cy="491"></circle>
            </g>
          </svg>

          <svg
            className="svg-square"
            viewBox="0 0 220 192"
            width="220"
            height="192"
            fill="none"
          >
            <defs>
              <pattern
                id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  fill="currentColor"
                ></rect>
              </pattern>
            </defs>
            <rect
              width="220"
              height="192"
              fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
            ></rect>
          </svg>

          <div className="content-text">
            <div className="content-text__text">
              <div>Sistema de</div>
              <div>Inspecciones</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
