import { createSlice } from "@reduxjs/toolkit";
import {
  sendFormat,
  sendData,
  updateData,
  postData,
  getParametros,
} from "./menuGroupReducer.action";

const initialState = {
  campo: {
    ubigeoIndex: {
      IdArea: null,
      IdEdificio: null,
      IdInmueble: null,
      IdNivel: null,
    },
    parametros: [],
  },
  data: [],
  idFormato: null,
  idConfig: null,

  error: null,
  loading: false,
  success: false,
  loadingData: false,
  errorData: null,
  successData: false,
  loadingParametros: false,
};

export const menuGroupReducer = createSlice({
  name: "menuGroupReducer",
  initialState,
  reducers: {
    addCampo: (state, action) => {
      state.campo = action.payload;
    },

    refreshMenu: (state) => {
      state.campo = {
        ubigeoIndex: {
          IdArea: null,
          IdEdificio: null,
          IdInmueble: null,
          IdNivel: null,
        },
        parametros: [],
      };
    },
    addId: (state, action) => {
      const { idFormato, idConfig } = action.payload;
      state.idFormato = idFormato;
      state.idConfig = idConfig;
    },

    updateField: (state, action) => {
      const { indiceParams, indiceSubcampo, text } = action.payload;
      let dataField = text;
      if (indiceSubcampo === undefined) {
        state.campo.parametros[indiceParams] = {
          ...state.campo.parametros[indiceParams],
          fieldValue: dataField,
          indiceParams,
        };
      } else {
        state.campo.parametros[indiceParams].subCampos[indiceSubcampo] = {
          ...state.campo.parametros[indiceParams].subCampos[indiceSubcampo],
          fieldValue: dataField,
          indiceParams,
          indiceSubcampo,
        };
      }
    },

    updateCorrectivo: (state, action) => {
      const { indiceParams, indiceSubcampo, requiereCorrectivoPregunta } =
        action.payload;

      if (indiceSubcampo === undefined) {
        state.campo.parametros[indiceParams] = {
          ...state.campo.parametros[indiceParams],
          requiereCorrectivoPregunta,
        };
      } else {
        state.campo.parametros[indiceParams].subCampos[indiceSubcampo] = {
          ...state.campo.parametros[indiceParams].subCampos[indiceSubcampo],
          requiereCorrectivoPregunta,
        };
      }
    },

    updateTextObserver: (state, action) => {
      const { indiceParams, indiceSubcampo, textoObservacion } = action.payload;

      if (indiceSubcampo === undefined) {
        state.campo.parametros[indiceParams] = {
          ...state.campo.parametros[indiceParams],
          textoObservacion,
          indiceParams,
        };
      } else {
        state.campo.parametros[indiceParams].subCampos[indiceSubcampo] = {
          ...state.campo.parametros[indiceParams].subCampos[indiceSubcampo],
          textoObservacion,
          indiceParams,
          indiceSubcampo,
        };
      }
    },
    updateDescripcion: (state, action) => {
      const { indiceParams, indiceSubcampo, recomendacionPregunta } =
        action.payload;

      if (indiceSubcampo === undefined) {
        state.campo.parametros[indiceParams] = {
          ...state.campo.parametros[indiceParams],
          recomendacionPregunta,
          indiceParams,
        };
      } else {
        state.campo.parametros[indiceParams].subCampos[indiceSubcampo] = {
          ...state.campo.parametros[indiceParams].subCampos[indiceSubcampo],
          recomendacionPregunta,
          indiceParams,
          indiceSubcampo,
        };
      }
    },

    updateRquiereAdjuntos: (state, action) => {
      const {
        indiceParams,
        indiceSubcampo,
        arrayAdjuntos,
        uuidFileNamePregunta,
        fileNamePregunta,
        requiereAdjuntosPregunta,
      } = action.payload;

      if (indiceSubcampo === undefined) {
        state.campo.parametros[indiceParams] = {
          ...state.campo.parametros[indiceParams],
          arrayAdjuntos,
          uuidFileNamePregunta,
          fileNamePregunta,
          requiereAdjuntosPregunta,
          indiceParams,
        };
      } else {
        state.campo.parametros[indiceParams].subCampos[indiceSubcampo] = {
          ...state.campo.parametros[indiceParams].subCampos[indiceSubcampo],
          arrayAdjuntos,
          uuidFileNamePregunta,
          fileNamePregunta,
          requiereAdjuntosPregunta,
          indiceParams,
          indiceSubcampo,
        };
      }
    },

    updateConclusion: (state, action) => {
      state.campo.conclusion = action.payload;
    },
    addChecked: (state, action) => {
      state.campo.solicitaTicket = action.payload;
    },
    clearData: (state, action) => {
      state.data = [];
      state.error = null;
      state.loading = false;
      state.success = false;
      state.loading = false;
      state.loadingData = false;
      state.errorData = null;
      state.successData = false;
      state.loadingParametros = false;
    },
    addParamData: (state, action) => {
      state.campo.solicitaTicket = state.campo.parametros[0].solicitaTicket;
      state.campo.conclusion = state.campo.parametros[0].conclusion;
      state.campo.recomendacion = state.campo.parametros[0].recomendacion;
    },

    checkedTicket: (state, action) => {
      state.campo.solicitaTicket = action.payload;
    },
    addRecomendacion: (state, action) => {
      state.campo.recomendacion = action.payload;
    },
    clearParametros: (state, action) => {
      state.campo.parametros = [];
    },
    clearState: (state) => {
      state.campo = {
        parametros: [],
      };
      state.error = null;
      state.loading = false;
      state.success = false;
      state.loading = false;
      state.loadingData = false;
      state.errorData = null;
      state.successData = false;
      state.loadingParametros = false;
    },
    clearStateParametros: (state, action) => {
      state.campo = {
        ...state.campo,
        parametros: action.payload,
        solicitaTicket: null,
        conclusion: null,
        recomendacion: null,
      };
      state.error = null;
      state.loading = false;
      state.success = false;
      state.loading = false;
      state.loadingData = false;
      state.errorData = null;
      state.successData = false;
      state.loadingParametros = false;
    },
    postDataOffline: (state, action) => {
      state.data = action.payload;
    },
    addParametrosOffline: (state, action) => {
      state.campo.parametros = action.payload.parametros;
      state.campo.conclusion = action.payload.conclusion;
      state.campo.recomendacion = action.payload.recomendacion;
      state.campo.solicitaTicket = action.payload.solicitaTicket;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendFormat.fulfilled, (state, action) => {
        state.loading = true;

        action.payload.forEach((elemento) => {
          const {
            fileDb,
            indiceParams,
            indiceSubcampo,
            fieldValue,
            fileNamePregunta,
            uuidFileNamePregunta,
          } = elemento;
          
          if (indiceParams !== undefined) {
            if (indiceSubcampo === undefined) {
              state.campo.parametros[indiceParams].fileDb = fileDb;
              state.campo.parametros[indiceParams].fileNamePregunta =
                fileNamePregunta;
              state.campo.parametros[indiceParams].uuidFileNamePregunta =
                uuidFileNamePregunta;
              if (typeof fieldValue === "object") {
                state.campo.parametros[indiceParams].fieldValue = "{}";
              }

              if (!fileNamePregunta) {
                state.campo.parametros[indiceParams].fileNamePregunta = null;
              }

              if (!uuidFileNamePregunta) {
                state.campo.parametros[indiceParams].uuidFileNamePregunta =
                  null;
              }
            } else {
              state.campo.parametros[indiceParams].subCampos[
                indiceSubcampo
              ].fileDb = fileDb;

              state.campo.parametros[indiceParams].subCampos[
                indiceSubcampo
              ].fileNamePregunta = fileNamePregunta;

              state.campo.parametros[indiceParams].subCampos[
                indiceSubcampo
              ].uuidFileNamePregunta = uuidFileNamePregunta;

              if (typeof fieldValue === "object") {
                state.campo.parametros[indiceParams].subCampos[
                  indiceSubcampo
                ].fieldValue = "{}";
              }

              if (!fileNamePregunta) {
                state.campo.parametros[indiceParams].subCampos[
                  indiceSubcampo
                ].fileNamePregunta = null;
              }

              if (!uuidFileNamePregunta) {
                state.campo.parametros[indiceParams].subCampos[
                  indiceSubcampo
                ].uuidFileNamePregunta = null;
              }
            }
          } else {
            console.log("entro undifined");
          }
        });
      })
      .addCase(sendFormat.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(sendFormat.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      });

    builder
      .addCase(sendData.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(sendData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });
    builder
      .addCase(updateData.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(updateData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.success = false;
      });

    builder
      .addCase(postData.pending, (state) => {
        state.loadingData = true;
      })
      .addCase(postData.fulfilled, (state, action) => {
        state.loadingData = false;
        state.successData = true;
        state.data = action.payload;
      })
      .addCase(postData.rejected, (state, action) => {
        state.loadingData = false;
        state.errorData = action.error.message;
      });

    builder.addCase(getParametros.pending, (state) => {
      state.loadingParametros = true;
    });
    builder
      .addCase(getParametros.fulfilled, (state, action) => {
        state.loadingParametros = false;
        state.campo.parametros = action.payload;
      })
      .addCase(getParametros.rejected, (state, action) => {
        state.loadingParametros = false;
        state.error = action.error.message;
      });
  },
});

export const {
  addCampo,
  updateTextObserver,
  updateField,
  refreshMenu,
  addId,
  updateConclusion,
  addChecked,
  clearState,
  clearData,
  addParamData,
  clearParametros,
  addRecomendacion,
  postDataOffline,
  addParametrosOffline,
  checkedTicket,
  clearStateParametros,
  updateRquiereAdjuntos,
  updateCorrectivo,
  updateDescripcion,
} = menuGroupReducer.actions;
export default menuGroupReducer.reducer;
