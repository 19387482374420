import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import { anularInspeccion } from "../../../../../../../../../store/technicalFormatoReducer/technicalFormatReducer.action";


import { Button } from "@mui/material";
const FooterModalAnular = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const { idFormato } = useParams();

  const [loading, setloading] = useState(false);
  
  const handleSend = () => {
    setloading(!loading);
    dispatch(anularInspeccion({idFormato}))
  };

  return (
    <div className="button-format_modal">
      <h2 id="parent-modal-title">Anular Inspección</h2>
      <p id="parent-modal-description">¿Desea Anular la Inspección ?</p>
      <div className="content-btn">
        <Button
          onClick={async () => await handleSend()}
          variant="contained"
          size="small"
        >
          Si
        </Button>
        <Button
          color="error"
          variant="contained"
          size="small"
          onClick={handleCloseModal}
        >
          No
        </Button>
      </div>
    </div>
  );
};

export default FooterModalAnular;
