import {useNavigate} from "react-router-dom";
import {Box, Typography, Button, Grid, Container, Paper} from "@mui/material";
import tgestiona from "../../../img/tgestiona.png";
import "./Welcome.css";

const Welcome = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/dashboard/inspections/tray");
    };

    return (
        <Container maxWidth={"xl"} sx={{paddingTop: 5}}>
            <Paper elevation={1}>
                <Grid container>
                    <Grid item xs={6} padding={7}>
                        <div className="contentWelcome__text">
                            <h2> ¡BIENVENIDO! </h2>
                            <span>SISTEMA DE INSPECCIONES</span>
                            <Button
                                variant="contained"
                                sx={{marginTop: "5rem"}}
                                onClick={handleClick}
                            >
                                Ir Inspecciones
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={6} className={"contentInspecciones"}>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default Welcome;
