import { uploadFile, deleteFile } from "../../helpers/azure/azureStorage";
import { actualizarParamsAsync } from "../../api/menuGroup";

async function getData(arrCopy) {
  try {
    let arrayError = [];
    let auxData = {
      fileName: null,
      peso: null,
      uuidFileName: null,
      extension: null,
    };

    for await (const field of arrCopy) {
      const { fieldValue, fileDb, arrayAdjuntos, id } = field;

      //Es un solo un objeto o archivo
      if (Array.isArray(fieldValue) === false) {
        const { name, size } = fieldValue;
        const extension = "." + name?.split(".").pop();
        // Archivos
        if (arrayAdjuntos) {
          let namesadjuntos = [];
          let uuidFileNamesadjuntos = [];
          
          for await (const file of arrayAdjuntos) {
            const { name, size } = file;
            if (name) namesadjuntos.push(name);
            
            try {
              if (typeof arrayAdjuntos === "object") {
                if(size){
                  const { uuidFileName } = await uploadFile(file, name);
                  if (uuidFileName === null) {
                    arrayError.push(uuidFileName);
                  }
                  uuidFileNamesadjuntos.push(uuidFileName);
                }else{
                  alert("Subir Archivo con tamaño mayor 0 Kb");
                  arrayError.push(arrayAdjuntos);
                }
              }
            } catch (error) {
              console.log(error, "error al eliminar archivo");
            }
          }

          field.fileNamePregunta = namesadjuntos.join();
          field.uuidFileNamePregunta = uuidFileNamesadjuntos.join();
        }

        try {
          if (fileDb?.uuidFileName && fieldValue !== "{}") {
            //si hay existe en db eliminar
            await actualizarParamsAsync(id);
            await deleteFile(fileDb.uuidFileName);
          }
        } catch (error) {
          console.log(error, "error al eliminar archivo");
        } finally {
          // si existe no tocar,si no existe subir

          if (fileDb?.uuidFileName && fieldValue === "{}") {
            auxData = fileDb;
          }
          if (typeof fieldValue === "object" && fieldValue !== "{}") {

            if(size){
              const { uuidFileName } = await uploadFile(fieldValue, name);
              if (uuidFileName === null) {
                arrayError.push(uuidFileName);
              }
              auxData = {
                fileName: name,
                peso: size.toString(),
                extension,
                uuidFileName,
              };
            }else{
              alert("Subir Imagen con tamaño mayor 0 Kb");
              arrayError.push(fieldValue);
            }
          }

          field.fileDb = auxData;
        }
      }

      //Son varios objetos o archivos
      if (Array.isArray(fieldValue)) {
        let names = [];
        let sizes = [];
        let uuidFileNames = [];
        let extensions = [];
        //Archivos
        if (arrayAdjuntos) {
          let namesadjuntos = [];
          let uuidFileNamesadjuntos = [];

          for await (const file of arrayAdjuntos) {
            const { name, size } = file;
            const extension = "." + name?.split(".").pop();
            if (name) namesadjuntos.push(name);
            
            try {
              if (typeof arrayAdjuntos === "object") {
                if(size){
                  const { uuidFileName } = await uploadFile(file, name);
                  if (uuidFileName === null) {
                    arrayError.push(uuidFileName);
                  }
  
                  uuidFileNamesadjuntos.push(uuidFileName);
                }else{
                  alert("Subir Archivos con tamaño mayor 0 Kb");
                  arrayError.push(arrayAdjuntos);
                }
              }
            } catch (error) {
              console.log(error, "error al eliminar archivo");
            }
          }

          field.fileNamePregunta = namesadjuntos.join();
          field.uuidFileNamePregunta = uuidFileNamesadjuntos.join();
        }
        //Imagenes
        for await (const file of fieldValue) {
          const { name, size } = file;
          const extension = "." + name?.split(".").pop();
          if (name) names.push(name);
          if (size) sizes.push(size);
          if (extension) extensions.push(extension);
          try {
            if (typeof fieldValue === "object") {
              if(size){
                const { uuidFileName } = await uploadFile(file, name);
                if (uuidFileName === null) {
                  arrayError.push(uuidFileName);
                }
  
                uuidFileNames.push(uuidFileName);
              }else{
                alert("Subir Imagenes con tamaño mayor 0 Kb ");
                arrayError.push(file);
              }
            }
          } catch (error) {
            console.log(error, "error al eliminar archivo");
          }
        }
        auxData = {
          fileName: names.join(),
          peso: sizes.join(),
          extension: extensions.join(),
          uuidFileName: uuidFileNames.join(),
        };

        field.fileDb = auxData;
      }
    }
    let objt = { arrayError, arrCopy };
    return objt;
  } catch (error) {
    let arrayError = [];
    let arrCopy = [];

    let objt = { arrayError, arrCopy };
    return objt;
  }
}

export default getData;
