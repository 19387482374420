import { Button } from "@mui/material";
const SendButton = ({ eventClick }) => {
  return (
    <Button onClick={eventClick} variant="contained" size="small">
      OK
    </Button>
  );
};

export default SendButton;
