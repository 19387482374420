import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialReactTable from "material-react-table";
import { esES } from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { addInmuebles } from "../../../../../store/planificationInspectionReducer/planificationInspectionReducer.reducer";

const data = [
  {
    name: {
      firstName: "INM-0121",
      lastName: "Doe",
    },

    inmueble: "East Daphne",
    address: "261 Erdman Ford",
    cliente: "Kentucky",
  },
  {
    name: {
      firstName: "INM-0122",
      lastName: "Doe",
    },

    inmueble: "Columbus",
    address: "769 Dominic Grove",
    cliente: "Ohio",
  },
  {
    name: {
      firstName: "INM-0123",
      lastName: "Doe",
    },

    inmueble: "South Linda",
    address: "566 Brakus Inlet",
    cliente: "West Virginia",
  },
  {
    name: {
      firstName: "INM-0124",
      lastName: "Vandy",
    },

    inmueble: "Lincoln",
    address: "722 Emie Stream",
    cliente: "Nebraska",
  },
  {
    name: {
      firstName: "INM-01215",
      lastName: "Rolluffs",
    },

    inmueble: "Charleston",
    address: "32188 Larkin Turnpike",
    cliente: "South Carolina",
  },
];

const Inmuebles = () => {
  const [rowSelection, setRowSelection] = useState(null);
  const { inmuebles } = useSelector((state) => state.planificationInspect);

  const dispatch = useDispatch();
  const theme = useTheme();
  const columns = useMemo(
    () => [
      {
        accessorKey: "name.firstName", //access nested data with dot notation
        header: "Código",
      },
      {
        accessorKey: "name.lastName",
        header: "Uso Genérico",
      },
      {
        accessorKey: "inmueble", //normal accessorKey
        header: "Inmueble",
      },
      {
        accessorKey: "address",
        header: "Dirección",
      },
      {
        accessorKey: "cliente",
        header: "Cliente",
      },
    ],
    []
  );

  const handleChange = (e) => {
    if (inmuebles) {
      const data = {};
      inmuebles?.forEach((inmueble) => {
        let item = JSON.stringify(inmueble);
        data[item] = true;
      });

      setRowSelection({ ...data });
    }
    return setRowSelection(e);
  };

  const handleState = () => {
    const data = {};
    inmuebles?.forEach((inmueble) => {
      let item = JSON.stringify(inmueble);
      data[item] = true;
    });

    return { rowSelection: data };
  };

  useEffect(() => {
    //do something when the row selection changes...
    handleState();
    if (rowSelection) {
      dispatch(addInmuebles(Object.keys(rowSelection)));
    }
  }, [rowSelection]);
  return (
    <ThemeProvider theme={createTheme(theme, esES)}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        localization={{
          actions: "Acciones",
          cancel: "Cancelar",
          changeFilterMode: "Cambia el modo de filtro",
          clearFilter: "Filtro claro",
          clearSearch: "Borrar búsqueda",
          clearSort: "Ordenar claro",
          columnActions: "Acciones de columna",
          edit: "Editar",
          expand: "Expandir",
          expandAll: "Expandir todo",
          filterByColumn: "Filtrar por {column}",
          filterMode: "Modo de filtro: {filterType}",
          grab: "Agarrar",
          groupByColumn: "Agrupar por {column}",
          groupedBy: "Agrupados por ",
          hideAll: "Ocultar todo",
          hideColumn: "Ocultar columna de {column}",
          rowActions: "Acciones de fila",
          pinToLeft: "Alfile a la izquierda",
          pinToRight: "Alfile a la derecha",
          save: "Salvar",
          search: "Búsqueda",
          selectedCountOfRowCountRowsSelected:
            "{selectedCount} de {rowCount} fila(s) seleccionadas",
          showAll: "Mostrar todo",
          showAllColumns: "Mostrar todas las columnas",
          showHideColumns: "Mostrar/Ocultar columnas",
          showHideFilters: "Alternar filtros",
          showHideSearch: "Alternar búsqueda",
          sortByColumnAsc: "Ordenar por {column} ascendente",
          sortByColumnDesc: "Ordenar por {column} descendiendo",
          thenBy: ", entonces por ",
          toggleDensity: "Alternar relleno denso",
          toggleFullScreen: "Alternar pantalla completa",
          toggleSelectAll: "Seleccionar todo",
          toggleSelectRow: "Seleccionar fila",
          ungroupByColumn: "Desagrupar por {column}",
          unpin: "Quitar pasador",
          unsorted: "Sin clasificar",
        }}
        enableSelectAll={false}
        getRowId={(row) => JSON.stringify(row)}
        onRowSelectionChange={handleChange} //connect internal row selection state to your own
        state={handleState()}
      />
      ;
    </ThemeProvider>
  );
};

export default Inmuebles;
