import { useEffect, useState } from "react";
import ButtonList from "../buttonList/ButtonList";
import { useDispatch } from "react-redux";
import {
  addParameters,
  deleteGroup,
  addNameGroup,
} from "../../../../../store/formatReducer/formatoReducer.reducer";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./GroupFormat.css";
import ModalClose from "../../../../components/modalClose/ModalClose";

const GroupFormat = ({ grupo, indice, indiceSection }) => {
  const dispatch = useDispatch();
  const [bolGroup, setBolGroup] = useState(true);
  const [nameGroup, setNameGroup] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const inputDefault = {
    indiceSection,
    indice,
    field: {
      idParametro: 1,
      field_id: "texto",
      fieldLabel: "",
      fieldMandatory: false,
      fieldEditable: true,
      fieldVisible: true,
      fieldColumn: 0,
      fieldRow: 0,
      fieldPlaceholder: "Ingresa Texto",
      fieldType: "text",
      fieldValue: "",
      subCampos: [],
    },
  };
  const handleClick = () => {
    //console.log(inputDefault);
    dispatch(addParameters(inputDefault));
  };
  const handleDelete = (grupo) => {
    dispatch(deleteGroup({ indiceSection, indiceGroup: indice, grupo }));
    setOpen(false);
  };
  const handleEdit = () => {
    setBolGroup(false);
  };
  const handleGroup = (e) => {
    setNameGroup(e.target.value);
  };
  useEffect(() => {
    if (nameGroup !== null) {
      dispatch(addNameGroup({ indiceSection, indiceGroup: indice, nameGroup }));
    }
  }, [nameGroup]);

  return (
    <>
      <div className="content-group_input">
        <input
          type="text"
          disabled={bolGroup}
          name="nameGroup"
          className="input-group"
          value={grupo.nombre}
          onChange={handleGroup}
        />
        <IconButton
          aria-label="edit"
          size="small"
          className="content-icon_edit"
          onClick={handleEdit}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          className="content-icon_delete"
          onClick={handleOpen}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="content-group">
        <div className="content-group-params">
          <Button
            type="button"
            variant="contained"
            className="btn-add-param"
            onClick={handleClick}
          >
            <span className="sumCode">&#43;</span> Agregar Campo
          </Button>
          {grupo?.parametros.map((e, i) => {
            return (
              <>
                <ButtonList
                  indice={{
                    indiceSection,
                    indiceGroup: indice,
                    indiceParams: i,
                    indiceSubcampo : undefined,
                  }}
                  field={e}
                  key={i + "buttonList"}
                />

                {
                  <div className="subcampo">
                    {e.subCampos.length ? (
                      e.subCampos.map((elemento, indiceSubcampo) => (
                        <ButtonList
                          indice={{
                            indiceSection,
                            indiceGroup: indice,
                            indiceParams: i,
                            indiceSubcampo: indiceSubcampo,
                          }}
                          field={elemento}
                          key={i + "buttonList"}
                        />
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                }
              </>
            );
          })}
        </div>
      </div>
      <ModalClose
        handleDelete={() => handleDelete(grupo)}
        text="Grupo"
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default GroupFormat;
