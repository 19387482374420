import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  ExportReport,
  ExportInforme,
} from "../../../../../../../api/report";
import ProgressLoading from "../../../../../../../components/progressLoading/ProgressLoading";
import moment from "moment";

const LoadingExport = () => {
  const [loading, setLoading] = useState(false);
  const { formato } = useSelector((state) => state.technicalFormato);
  const { loadingConfig, confingInspection, errorConfig } = useSelector(
    (stateConfig) => stateConfig.configInspection
  );
  const handleClick = async () => {
    setLoading(true);

    try {
      const html = await ExportReport(confingInspection?.id);

      var wnd = window.open("about:blank", "", "_blank");
      wnd.document.write(html);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    //    console.log(state.id, "idConfig");
  };

  const handleClickInforme = async () => {
    setLoading(true);

    try {
      const html = await ExportInforme(confingInspection?.id);

      var wnd = window.open("about:blank", "", "_blank");
      wnd.document.write(html);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    //    console.log(state.id, "idConfig");
  };


  return (
    <>
      <LoadingButton
        color="warning"
        disabled={formato?.estado === "ATENDIDA" ? false : true}
        loading={loading}
        onClick={handleClick}
        sx={{ ml: 2 }}
        variant="outlined"
        className="technical-exportInspect"
      >
        Exportar
      </LoadingButton>

      <LoadingButton
        color="success"
        disabled={formato?.estado === "ATENDIDA" ? false : true}
        loading={loading}
        onClick={handleClickInforme}
        sx={{ ml: 2 }}
        variant="outlined"
        className="technical-exportInspect"
      >
        Informe
      </LoadingButton>
      
      <Dialog open={loading}>
        <div style={{ AlignText: "center" }}>
          <DialogTitle>Generando PDF...</DialogTitle>
          <div style={{ textAlign: "center" }}>
            <CloudDownloadIcon
              color="primary"
              sx={{ fontSize: "4rem" }}
            ></CloudDownloadIcon>
          </div>
        </div>

        <DialogContent>
          <ProgressLoading></ProgressLoading>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoadingExport;
