import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import "./ModalImg.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalImg = ({ imgAzure, nameImg }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <IconButton onClick={handleOpen} variant="outlined">
        <RemoveRedEyeIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box  className="modalImg_box">
          <div   className="modalImg_box-img">
            <img src={imgAzure} alt={nameImg} style={{ width: "100%",height:"100%" }} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalImg;
