import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../store/formatReducer/formatoReducer.reducer";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuItem, Menu, Button } from "@mui/material";
import SubItems from "./subItems/subItems";

import "./SubMenu.css";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};

const SubMenu = ({ listName, data, indice, paramsId }) => {
  const dispatch = useDispatch();
  const { formato } = useSelector((state) => state.formato);
  let propiedades =
    indice.indiceSubcampo === undefined
      ? formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams]
      : formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo];
  const {
    observacion,
    requiereCorrectivo,
requiereAdjuntos,
    fieldLabel,
    fieldPlaceholder,
    fieldMandatory,
    fieldEditable,
    fieldVisible,
    fieldColumn,
    fieldRow,
    fileDb,
  } = propiedades;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButton = (el) => {
    if (el) {
      switch (el) {
        case "Texto Largo":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                id: paramsId,
                idParametro: 3,
                fileDb,
                field_id: "multiline",
                fieldLabel: fieldLabel,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldPlaceholder: fieldPlaceholder,
                fieldType: "multiline",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );

          break;

        case "Sólo etiqueta":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                id: paramsId,
                idParametro: 8,
                fileDb,
                field_id: "label",
                fieldLabel: fieldLabel,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldPlaceholder: fieldPlaceholder,
                fieldType: "textLabel",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;

        case "Archivo":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                id: paramsId,
                idParametro: 4,
                indice,

                fieldLabel: fieldLabel,
                field_id: "upload",
                fileDb,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldType: "archivo",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;

        case "Imagen":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                id: paramsId,
                idParametro: 6,
                indice,
                fieldLabel: fieldLabel,
                field_id: "imagen",
                fileDb,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldType: "imagen",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;
        case "Imagenes":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                id: paramsId,
                idParametro: 13,
                indice,
                fieldLabel: fieldLabel,
                field_id: "imagenes",
                fileDb,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldType: "imagenes",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;

        case "Archivos":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                id: paramsId,
                idParametro: 14,
                indice,
                fieldLabel: fieldLabel,
                field_id: "Archivos",
                fileDb,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldType: "Archivos",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;
      }
    }

    setAnchorEl(null);
  };
  return (
    <>
      <MenuItem
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ width: "none", minWidth: "none" }}
      >
        {listName}
        {open ? <ArrowRightIcon /> : <ArrowDropUpIcon />}
      </MenuItem>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...popoverPropiert}
      >
        {data?.map((el, id) =>
          typeof el === "object" ? (
            <SubItems
              name={el.name}
              data={el.propiert}
              indice={indice}
              key={id + "data"}
              paramsId={paramsId}
            />
          ) : (
            <MenuItem onClick={() => handleButton(el)} key={id + "data"}>
              {el}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};

export default SubMenu;
