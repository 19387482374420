import * as React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
/*
    clearRegisterInspection: (state, action) => {
      state.myClient = null;
      state.RegisterData.cliente = null;
      state.RegisterData.inmueble = null;
      state.RegisterData.startDate = null;
      state.RegisterData.startTime = null;


      state.RegisterData.plantilla = null;
      state.RegisterData.proveedor = null;
      state.RegisterData.tecnicos = [];
      state.RegisterData.tipoInspeccion = null;
      state.RegisterData.inspectoresFm = [];
      state.RegisterData.responsable = null;
    }

*/
const SelectFormat = () => {
  const { RegisterData } = useSelector((state) => state.registerInspect);

  return (
    <Box sx={{ flexGrow: 1, marginTop: "3rem ", marginBottom: "3rem" }}>
      <h2>Resumen</h2>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={6}>
          <Item>
            <h3>Plantilla y Técnicos</h3>
            <div style={{ textAlign: "left" }}>
              <h4>Plantilla :</h4>
              <span>
                {`${RegisterData?.plantilla?.codigo}-
                ${RegisterData?.plantilla?.nombre}` || "----"}
              </span>
              <h4> Proveedor :</h4>
              <span>{RegisterData?.proveedor?.nombre || "----"}</span>
              <h4>Técnicos :</h4>
              <ul>
                {RegisterData?.tecnicos.map((e, index) => (
                  <li key={index + "tecnicos"}>{e.label}</li>
                ))}
              </ul>
              <h4>Técnico Responsable :</h4>
              <span>{RegisterData?.tecnicoResponsable?.label || "----"}</span>
              <h4>Tipo de Inspección :</h4>
              <span>{RegisterData?.tipoInspeccion || "----"}</span>
              <h4>InspectoresFm :</h4>
              <ul>
                {RegisterData?.inspectoresFm.map((el, index) => (
                  <li key={index + "inspectoresFm"}>{el.label}</li>
                ))}
              </ul>
              <h4>Responsable :</h4>
              <span>{RegisterData?.responsable?.label || "----"}</span>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4} sm={8} md={6}>
          <Item>
            <h3>Inmueble y Periodo</h3>
            <div style={{ textAlign: "left" }}>
              <h4>Cliente :</h4>
              <span>{RegisterData?.cliente?.label || "----"}</span>
              <h4> Inmueble :</h4>
              <span>{RegisterData?.inmueble.length!==1? "----": RegisterData?.inmueble[0].label}</span>
              <h4>Fecha Inicial</h4>
              <span>
                {moment(RegisterData?.startDate).format("YYYY-MM-DD")}
              </span>
              <h4>Hora Inicial</h4>
              <span>
                {moment(RegisterData?.startTime).format("hh:mm:ss a ")}
              </span>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectFormat;
