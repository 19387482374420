import { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";

import { addField } from "../../../../../../store/formatReducer/formatoReducer.reducer";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};

const SubItems = ({ name, data, indice, paramsId }) => {
  const dispatch = useDispatch();
  const { formato } = useSelector((state) => state.formato);

  let propiedades =
    indice.indiceSubcampo === undefined
      ? formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams]
      : formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo];

  const {
    observacion,
    requiereCorrectivo,
requiereAdjuntos,
    fieldLabel,
    fieldPlaceholder,
    fieldMandatory,
    fieldEditable,
    fieldVisible,
    fieldColumn,
    fieldRow,
    fileDb,
  } = propiedades;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleButton = (e, el) => {
    if (el) {
      switch (el) {
        case "Input":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                id: paramsId,
                idParametro: 1,
                fileDb,
                field_id: "texto",
                fieldLabel: fieldLabel,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldPlaceholder: fieldPlaceholder,
                fieldType: "text",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );

          break;

        case "DNI":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                id: paramsId,
                idParametro: 11,
                fileDb,
                field_id: "texto", //dni
                fieldLabel: fieldLabel,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldPlaceholder: fieldPlaceholder,
                fieldType: "dni",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;

        case "Correo":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                id: paramsId,
                idParametro: 12,
                fileDb,
                field_id: "texto", //correo
                fieldLabel: fieldLabel,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldPlaceholder: fieldPlaceholder,
                fieldType: "email",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;

        case "Otro Validar":
          break;
      }
    }
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {name}
        {open ? <ArrowRightIcon /> : <ArrowDropUpIcon />}
      </MenuItem>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleButton}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...popoverPropiert}
      >
        {data?.map((el, i) => (
          <MenuItem onClick={(e) => handleButton(e, el)} key={i + "subData"}>
            {el}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SubItems;
