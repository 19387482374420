import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  clearFormat,
  clearErrorAction,
  clearSuccess,
} from "../../../../../../../store/technicalFormatoReducer/technicalFormatReducer.reducer";

import ProgressLoading from "../../../../../../../components/progressLoading/ProgressLoading";
import FooterModalAnular from "./components/footerModal/FooterModalAnular";
import SendButton from "./components/sendButton/SendButton";

import { Modal } from "@mui/material";
import { useEffect } from "react";

const SendModalAnular = ({
  setOpenModalAnular,
  openModalAnular,
  open2,
  setOpen2,
}) => {
  const { loadingFormat, success, error } = useSelector(
    (state) => state.technicalFormato
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dashboard/inspections/tray");
  };
  useEffect(() => {
    if (error) {
      setOpen2(true);
      setTimeout(() => {
        setOpen2(false);
        dispatch(clearErrorAction());
        setOpenModalAnular(false);
      }, 2500);
    }
    if (success) {
      setOpen2(true);
      setTimeout(() => {
        setOpen2(false);
        dispatch(clearSuccess());
        navigate("/dashboard/inspections/tray"); //redirect to ...
      }, 2500);
    }
  }, [error, success]);

  return (
    <Modal
      open={openModalAnular}
      onClose={() => setOpenModalAnular(!openModalAnular)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="content-modal_sendFormat">
        {loadingFormat ? (
          <ProgressLoading />
        ) : open2 ? (
          <div className="button-format_modal">
            <h2 id="parent-modal-title">
              {error || "Anular Inspección"}
            </h2>
            {error ? (
              <SendButton
                eventClick={() => setOpenModalAnular(!openModalAnular)}
              />
            ) : (
              <SendButton eventClick={handleNavigate} />
            )}
          </div>
        ) : (
          <FooterModalAnular
            handleCloseModal={() => setOpenModalAnular(!openModalAnular)}
          />
        )}
      </div>
    </Modal>
  );
};

export default SendModalAnular;
