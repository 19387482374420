import ax from "../../helpers/axiosConfig";
import { encriptar } from "../../helpers/encrypt";


const EDI= process.env.REACT_APP_ENPOINT_EDI;

export const getLoginAsync = (data) =>
  ax.get(`/api/Seguridad/Opciones/${data?.IdUser}`);

export const getLogoutAsync = () => ax.get(`/api/v1/logout`);


export const postLoginAsync = async(obj) =>{

  const data = await encriptar(obj);

    return ax.post(`${EDI}/Autenticacion`, data);


}
