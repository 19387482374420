import { Link } from "react-router-dom";
import "../pages.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {clearUser} from "../../store/authReducer/authReducer.reducer";


const TokenAspired = () => {
//   const dispatch = useDispatch();
// useEffect(() => {
//   console.log("token aspired");
//   localStorage.removeItem("accessToken");
//   localStorage.removeItem("accessEdi");
//   dispatch(clearUser());
  
// },[])
  return (
    <div className="notfoundContent">
      <div className="notfound">
        <div className="notfound-bg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>oops!</h1>
        <h2>El token ha expirado </h2>
        <Link to="/">
          Ingresar sistema EDI
        </Link>
      </div>
    </div>
  );
};

export default TokenAspired;
