import { postRegisterInspection } from "./registerInspectReducer.action";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  RegisterData: {
    cliente: null,
    inmueble: [],
    startDate: null,
    startTime: null,
    plantilla: null,
    proveedor: null,
    tecnicos: [],
    tipoInspeccion: null,
    inspectoresFm: [],
    responsable: null,
    tecnicoResponsable: null,
  },
  myClient: null,
  complete: false,
  error: null,
  loading: false,
  success: false,
};

export const registerInspectReducer = createSlice({
  name: "registerInspect",
  initialState,
  reducers: {
    clearBolResponse: (state, action) => {
      state.success = false;
      state.error = null;
    },
    clearRegisterInspection: (state, action) => {
      state.myClient = null;
      state.complete = false;
      state.RegisterData.cliente = null;
      state.RegisterData.inmueble = [];
      state.RegisterData.startDate = null;
      state.RegisterData.startTime = null;
      state.RegisterData.plantilla = null;
      state.RegisterData.proveedor = null;
      state.RegisterData.tecnicos = [];
      state.RegisterData.tipoInspeccion = null;
      state.RegisterData.inspectoresFm = [];
      state.RegisterData.responsable = null;
      state.RegisterData.tecnicoResponsable = null;
    },

    restoreMyClient: (state, action) => {
      state.RegisterData.cliente = null;
      state.myClient = null;
    },
    addCliente: (state, action) => {
 
      state.RegisterData.cliente = action.payload;
      console.log(state.RegisterData.cliente );
    },
    addInmueble: (state, action) => {
      state.RegisterData.inmueble = action.payload.map((e) => JSON.parse(e));
    },
    addStartDate: (state, action) => {
      state.RegisterData.startDate = action.payload;
    },
    addStartTime: (state, action) => {
      state.RegisterData.startTime = action.payload;
    },
    addPlantilla: (state, action) => {
      state.RegisterData.plantilla = action.payload;
    },
    addProveedor: (state, action) => {
      state.RegisterData.proveedor = action.payload;
    },
    addTecnicos: (state, action) => {
      state.RegisterData.tecnicos = action.payload.map((e) => JSON.parse(e));
    },

    addTipoInspeccion: (state, action) => {
      state.RegisterData.tipoInspeccion = action.payload.inspection;
    },
    addInspectoresFm: (state, action) => {
      state.RegisterData.inspectoresFm = action.payload.map((e) =>
        JSON.parse(e)
      );
    },
    addResponsable: (state, action) => {
      state.RegisterData.responsable = action.payload;
    },
    addTecResponsable: (state, action) => {
      state.RegisterData.tecnicoResponsable = action.payload;
    },
    updateComplete: (state, action) => {
      const { complete } = action.payload;
      state.complete = complete;
    },
    addMyclient: (state, action) => {
      const { myClient } = action.payload;

      state.myClient = myClient;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postRegisterInspection.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postRegisterInspection.fulfilled, (state, action) => {
        state.loading = false;

        state.success = action.payload;
      })
      .addCase(postRegisterInspection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  addCliente,
  updateComplete,
  addMyclient,
  restoreMyClient,
  addInmueble,
  addStartDate,
  addStartTime,
  addPlantilla,
  addProveedor,
  addTecnicos,
  addTipoInspeccion,
  addInspectoresFm,
  addResponsable,
  addTecResponsable,
  clearRegisterInspection,
  clearBolResponse,
} = registerInspectReducer.actions;
export default registerInspectReducer.reducer;
