import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  addStartTime,
  addStartDate,
} from "../../../../../store/technicalFormatoReducer/technicalFormatReducer.reducer";
import {
  TextField,
  Typography,
  Paper,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./HeaderFlat.css";

const HeaderFlat = () => {
  const { formato } = useSelector((state) => state.technicalFormato);
  const dispatch = useDispatch();

  const { state } = useLocation();
  const { loadingConfig, confingInspection, errorConfig } = useSelector(
    (stateConfig) => stateConfig.configInspection
  );

  const { user, offlineStore } = useSelector((stateAuth) => stateAuth.auth);

  const handleDate = (e) => {
    dispatch(addStartDate(e));
  };
  const handleTime = (e) => {
    dispatch(addStartTime(e));
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={
            <>
              Información de Inspección :{" "}
              {confingInspection?.codigo ?? state?.codigo}
            </>
          }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Cliente"
                value={
                  offlineStore === true
                    ? state?.cliente
                    : confingInspection?.cliente
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Inmueble"
                value={
                  offlineStore === true
                    ? state?.inmueble
                    : confingInspection?.inmueble
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Proveedor"
                value={
                  offlineStore === true
                    ? state?.proveedor
                    : confingInspection?.proveedor
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Tipo de Inspección"
                value={
                  offlineStore === true
                    ? state?.tipoInspeccion
                    : confingInspection?.tipoInspeccion
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Estado de la Inspección"
                value={
                  offlineStore === true
                    ? state?.estado
                    : confingInspection?.estado
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Inspector Responsable"
                value={
                  offlineStore === true
                    ? state?.estado
                    : confingInspection?.responsable
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Técnico Responsable"
                value={
                  offlineStore === true
                    ? state?.tecnicoResponsable
                    : confingInspection?.tecnicoResponsable
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="outlined-read-only-input"
                label="Fecha y Hora de Inicio"
                value={
                  formato?.fechaInicioReal
                    ? ` ${moment(formato?.fechaInicioReal)
                        .utc("America/Lima")
                        .format("DD/MM/YYYY")} - ${moment(
                        formato?.fechaInicioReal
                      )
                        .utc("America/Lima")
                        .format("h:mm:ss a")} `
                    : "---- ----- ----"
                }
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={4}>
              {formato?.estado === "ATENDIDA" && (
                <TextField
                  id="outlined-read-only-input"
                  label="Fecha y Hora de Fin"
                  value={`${moment(formato?.fechaFin)
                    .utc("America/Lima")
                    .format("DD/MM/YYYY")} - ${moment(formato?.horaFin)
                    .utc("America/Lima")
                    .format("h:mm:ss a")}  `}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    marginBottom: "1rem",
                    width: "100%",
                  }}
                />
              )}
            </Grid>
            <Grid item md={4}>
              {user?.ListRoles.find((e) => e === 18) === undefined &&
                formato?.estado !== "ATENDIDA" && (
                  <div className="headerFlat-span">
                    <span>Fecha y Hora de Fin: </span>

                    <div style={{ display: "flex" }}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Fecha "
                          value={formato?.fechaFin}
                          onChange={handleDate}
                          minDate={moment()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              className="headerFlat-textField"
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          label="Hora"
                          value={formato?.horaFin}
                          onChange={handleTime}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              sx={{ marginLeft: "2rem" }}
                              className="headerFlat-textField"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default HeaderFlat;
