import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { getTemplate } from "../../../api/format";

import { IconButton,  Button } from "@mui/material";
import "./TemplateTray.css";

import AdaptepTable from "../../components/adaptedTable/AdaptedTable";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector   } from "react-redux";

import moment from "moment";

const TemplateTray = () => {
  const [template, setTemplate] = useState([]);
  const [loading, setLoading] = useState(false);
  const { formato } = useSelector((state) => state.formato);
  const getDataASync = async () => {
    setLoading(true);
    try {
      const cliente = formato?.cliente;
      const clientejson = JSON.parse(localStorage.getItem("jsonCliente"));

      if (clientejson) {
        const { data } = await getTemplate(clientejson.id);
        setTemplate(data);
      } else {
        const { data } = await getTemplate(clientejson.id);
        setTemplate(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    getDataASync();
  }, [setTemplate]);

  const columns = useMemo(
    () => [
      { accessorKey: "codigo", header: "Codigo" },
      { accessorKey: "nombre", header: "Nombre" },
      { accessorKey: "labelCliente", header: "Cliente" },
      {
        accessorKey: "fechaReg",
        header: "Fecha de Registro",

        Cell: ({ row }) => {
          const { original } = row;
          const date = new Date(original.fechaReg);
          return moment(date).format("DD/MM/YYYY, h:mm:ss a");
        },
      },
      {
        accessorKey: "fechaMod",
        header: "Fecha de modificación",
        type: "date",
        Cell: ({ row }) => {
          const { original } = row;
          const date = moment(original.fechaMod);
          if (date.isValid())
            return moment(date).format("DD/MM/YYYY, h:mm:ss a");
          else return "";
        },
      },
      {
        accessorKey: "acciones",
        header: "Editar",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <Link
              to={`/dashboard/inspections/format/${original.id}`}
              style={{ textDecoration: "none" }}
            >
              <IconButton color="primary">
                <EditIcon />
              </IconButton>
            </Link>
          );
        },
      },
    ],
    []
  );

  const renderTopToolbarCustomActions = ({ table }) => {
    return (
      <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
        <Button
          variant={"contained"}
          component={Link}
          to={"/dashboard/inspections/format"}
          size="medium"
          color="success"
        >
          Nueva Plantilla
        </Button>
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      {/* <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1.5rem" }}>
        <Link
          to="/"
          style={{
            textDecoration: "auto",
            color: "inherit",
            mouseHover: "pointer",
          }}
        >
          Inspecciones
        </Link>
        <Typography color="text.primary">Bandeja de plantillas</Typography>
      </Breadcrumbs> */}
      <br></br>
      <br></br>
      <AdaptepTable
        columns={columns}
        data={template}
        loading={loading}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
    </div>
  );
};

export default TemplateTray;
