import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
//import { useNavigate } from "react-router";
import { postRegisterInspection } from "../../../../../store/registerInspectReducer/registerInspectReducer.action";
import { clearBolResponse } from "../../../../../store/registerInspectReducer/registerInspectReducer.reducer";
import ProgressLoading from "../../../../../components/progressLoading/ProgressLoading";


const SendModal = ({ setActiveStep, handleClose, open }) => {
  const [open2, setOpen2] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector(
    (state) => state.registerInspect
  );

  const handleSend = async () => {
  await dispatch(postRegisterInspection());
  };
  const handleOk = () => {
    setActiveStep(3);
    setOpen2(false);
    dispatch(clearBolResponse());
    handleClose();
  };

  useEffect(() => {
    if (error) {
      setOpen2(true);
      setTimeout(() => {
        setOpen2(false);
        setActiveStep(2);
        dispatch(clearBolResponse());
      }, 2500);
    }
    if (success) {
      setOpen2(true);
      setTimeout(() => {
        setOpen2(false);
        handleClose();
        setActiveStep(3);
        dispatch(clearBolResponse());
      }, 2500);
    }
  }, [error, success]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="content-modal_sendFormat">
        {loading ? (
          <ProgressLoading />
        ) : open2 ? (
          <div className="button-format_modal">
            <h2 id="parent-modal-title">{error || "Creado con éxito"}</h2>
            {error ? (
              <Button onClick={handleClose} variant="contained" size="small">
                OK
              </Button>
            ) : (
              <Button onClick={handleOk} variant="contained" size="small">
                OK
              </Button>
            )}
          </div>
        ) : (
          <div className="button-format_modal">
            <h2 id="parent-modal-title">Registrar Plan</h2>
            <p id="parent-modal-description">
              ¿Ustede desea Registrar el Plan de Inspección?
            </p>
            <div className="content-btn">
              <Button onClick={async () => await handleSend()} variant="contained" size="small">
                Confirmar
              </Button>
              <Button color="error" variant="contained" size="small" onClick={handleClose}>
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SendModal;
