import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  clearFormat,
  clearErrorAction,
  clearSuccess,
} from "../../../../../store/formatReducer/formatoReducer.reducer";
import ProgressLoading from "../../../../../components/progressLoading/ProgressLoading";
import FooterModal from "./footerModal/FooterModal";
import SendButton from "../sendButton/SendButton";

import { Modal } from "@mui/material";
import { useEffect } from "react";

const UpdateModal = ({ openModal2, setOpenModal2, open2, setOpen2 }) => {
  const { loadingFormat, success, error } = useSelector(
    (state) => state.formato
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(clearFormat());
    navigate("/dashboard/inspections/templateTray");
  };
  useEffect(() => {
    if (error) {
      setOpen2(true);
      setTimeout(() => {
        setOpen2(false);
        setOpenModal2(false);
        dispatch(clearErrorAction());
      }, 2500);
    }
    if (success) {
      setOpen2(true);
      setTimeout(() => {
        setOpen2(false);
        dispatch(clearSuccess());
        navigate("/dashboard/inspections/templateTray");
      }, 2500);
    }
  }, [error, success]);

  return (
    <Modal
      open={openModal2}
      onClose={() => setOpenModal2(!openModal2)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="content-modal_sendFormat">
        {loadingFormat ? (
          <ProgressLoading />
        ) : open2 ? (
          <div className="button-format_modal">
            <h2 id="parent-modal-title">
              {error || "Formato Actualizado con éxito"}
            </h2>
            {error ? (
              <SendButton eventClick={() => setOpenModal2(!openModal2)} />
            ) : (
              <SendButton eventClick={handleNavigate} />
            )}
          </div>
        ) : (
          <FooterModal handleCloseModal={() => setOpenModal2(!openModal2)} />
        )}
      </div>
    </Modal>
  );
};

export default UpdateModal;
