import axios from "axios";

axios.defaults.headers = {
  "Content-Type": "application/json",
};
const ax = axios.create({
  baseURL: `${
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_ENPOINT_PROD
      : process.env.REACT_APP_ENPOINT_DEV
  }`,
});
ax.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("accessToken");
    if (token) request.headers = { Authorization: "Bearer " + token };
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
ax.interceptors.response.use(
  (respuesta) => {
    return respuesta.data;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response === undefined) error.response = { status: 512 };
    if (error.response.status === 500) return Promise.reject(error);
    if (error.response.status === 401) return Promise.reject(error);
    if (error.response.status === 403 && !originalRequest._retry)
      return Promise.reject(error);
    return Promise.reject(error);
  }
);
export default ax;
