import TreeView from "@mui/lab/TreeView";
import MenuData from "./menuData/MenuData";
import { useState, useEffect } from "react";

import { searchInmueble } from "../../../../../../../api/ediServices";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ProgressLoading from "../../../../../../../components/progressLoading/ProgressLoading";
import useNetwork from "../../../../../../../hooks/useNetwork/UseNetwork";
import { useSelector } from "react-redux";
import { useIndexedDB } from "@slnsw/react-indexed-db";

const MenuList = ({ idInmueble, idCliente }) => {
  const [Inmuebles, setInmuebles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { offlineStore } = useSelector((state) => state.auth);

  const networkState = useNetwork();
  const { getByIndex } = useIndexedDB("inmuebles");
  const { online } = networkState;

  const getData = async () => {
    try {
      setLoading(true);
      const clientejson = JSON.parse(localStorage.getItem("jsonCliente"));
      let res = await searchInmueble((idCliente === 0 || idCliente === undefined)? clientejson.id : idCliente , idInmueble);

      //   const arrFilter = res.filter((el) => el.IdInmueble === idInmueble);

      setInmuebles(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (offlineStore === true) {
      if (idCliente) {
        setLoading(true);
        getByIndex("idCliente", idCliente).then((res) => {
          /*areglado seria solo inmueble */
          setInmuebles(res?.inmueble);
          setLoading(false);
        });
      }
    } else {
      getData();
    }
  }, [online, idCliente, offlineStore]);
  return (
    <TreeView
      aria-label="file system navigator"
      sx={{
        height: "35rem",
        flexGrow: 1,
        margin: "0 auto",
        overflowY: "auto",
      }}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {loading ? (
        <ProgressLoading />
      ) : (
        Inmuebles?.map((data, index) => (
          <MenuData
            data={data}
            key={"menu" + index}
            ubigeoIndex={{
              IdArea: data.IdArea,
              IdEdificio: data.IdEdificio,
              IdInmueble: data.IdInmueble,
              IdNivel: data.IdNivel,
              label: data.label,
            }}
            index={"menu" + index}
          />
        ))
      )}
    </TreeView>
  );
};

export default MenuList;
