import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRegisterInspectionAsync } from "../../api/inspection";

export const postRegisterInspection = createAsyncThunk(
  "postRegisterInspection/getData",
  async (dataParams, state) => {
    try {
      const { registerInspect } = state.getState();

      const { success } = await postRegisterInspectionAsync(
        registerInspect.RegisterData
      );

      return success;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
    //return formatos;
  }
);
