import { useDispatch } from "react-redux";
import {
  sendFormat,
  sendData,
} from "../../../../../../store/formatReducer/formatReducer.action";

import { Button } from "@mui/material";
const FooterModal = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const handleSend = () =>
    Promise.resolve(dispatch(sendFormat()).then(() => dispatch(sendData())));

  return (
    <div className="button-format_modal">
      <h2 id="parent-modal-title">Grabar plantilla</h2>
      <p id="parent-modal-description">¿Desea grabar la plantilla?</p>

      <div className="content-btn">
        <Button
          onClick={async () => await handleSend()}
          variant="contained"
          size="small"
        >
          Si
        </Button>
        <Button
          color="error"
          variant="contained"
          size="small"
          onClick={handleCloseModal}
        >
          No
        </Button>
      </div>
    </div>
  );
};

export default FooterModal;
