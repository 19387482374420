import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dowloadFile } from "./../../../helpers/azure/azureStorage";
import ModalImg from "./modalImg/ModalImg";
import { updateField } from "../../../store/menuGroupReducer/menuGroupReducer.reducer";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import useNetwork from "../../../hooks/useNetwork/UseNetwork";

import {
  Stack,
  Typography,
  Select,
  TextField,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  FormHelperText,
  Button,
  Box,
  ImageList,
  ImageListItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import "./ElementTechnical.css";
import { useEffect } from "react";
//import { useEffect } from "react";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const styleLabel = {
  marginTop: "1.5rem",
  fontWeight: "bold",
  marginBottom: "1rem",
};
const ElementTechnical = ({ field, indice, estado }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState(null);
  const [dni, setDni] = useState(null);
  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState("");
  const [arrayImgPreview, setArrayImgPreview] = useState([]);
  const [count, setCount] = useState(0);
  const networkState = useNetwork();
  const { online } = networkState;
  const { offlineStore } = useSelector((state) => state.auth);

  const [mandatory, setMandatory] = useState(null); //false
  const {
    field_id,
    fieldLabel,
    fieldPlaceholder,
    fieldMandatory,
    fieldEditable,
    fieldValue,
    fieldOptions,
    fileDb,
    idParametro,
  } = field;

  let aux = fieldMandatory;

  const handleChangeText = (e) => {
    if (e.target.value.length === 0) {
      setMandatory(aux === true ? true : false);

      setText(e.target.value);
    } else {
      setMandatory(false);
      setText(e.target.value);
    }
  };

  const handleDni = (e) => {
    if (e.target.value.length >= 9 || isNaN(e.target.value)) {
      return;
    } else {
      setDni(e.target.value);
    }

    if (e.target.value.length === 0) {
      setMandatory(aux === true ? true : false);
    } else {
      setMandatory(false);
    }
  };
  const handleFile = async (e) => {
    const file = e.target.files[0];

    setFile(file);
    if (file.type.slice(0, 5) === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImgPreview(reader.result);
          // dispatch(addPreview({ reader: reader.result, indice: indice }));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleChangeSelect = (e) => {
    if (e.target.value) {
      setMandatory(false);
      setText(e.target.value);
    } else {
      setMandatory(aux === true ? true : false);
    }
  };
  const hanleSwitch = (e) => {
    let eString = e.target.checked.toString();
    dispatch(
      updateField({
        ...indice,
        text: eString,
      })
    );
  };

  const handleFiles = async (e) => {
    let arrayFiles = [];
    let previewFiles = [];
    setArrayImgPreview([]);
    const files = e.target.files;

    for await (const file of files) {
      const reader = new FileReader();
      //fileReaders.push(reader);
      reader.onload = (e) => {
        const { result } = e.target;

        if (result) {
          previewFiles = previewFiles.concat(result);
        }
        if (previewFiles.length === files.length) {
          setArrayImgPreview(previewFiles);
        }
      };
      reader.readAsDataURL(file);
      arrayFiles.push(file);
      setCount(count + 1);
    }

    dispatch(updateField({ ...indice, text: arrayFiles }));
  };
  const handleArchives = async (e) => {
    let arrayArchives = [];

    const files = e.target.files;
    //aceptar solo 2 o mas archivos

    for await (const file of files) {
      arrayArchives.push(file);
      setCount((prev) => prev + 1);
    }

    dispatch(updateField({ ...indice, text: arrayArchives }));
  };

  const handleDowloadFile = async (uuidfile, fileName) => {
    const arr = uuidfile.split(",");
    const arrName = fileName.split(",");

    const array = arr.map((item, index) => ({
      file: item,
      name: arrName[index],
    }));

    for await (const element of array) {
      let file = await dowloadFile(element.file);
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", element.name);
      document.body.appendChild(link);
      link.click();
    }
  };
  const handleMultiple = (event) => {
    const {
      target: { value },
    } = event;

    if (value) {
      setMandatory(false);
      setText(value.join(","));
    } else {
      setMandatory(aux === true ? true : false);
    }
  };

  useEffect(() => {
    if (text !== null) {
      dispatch(updateField({ ...indice, text }));
    }
    if (dni !== null) {
      dispatch(updateField({ ...indice, text: dni }));
    }

    if (file !== null) {
      dispatch(updateField({ ...indice, text: file }));
    }

    if (offlineStore === true) {
      if (idParametro === 13 && typeof fieldValue === "object") {
        let previewFiles = [];
        for (const file of fieldValue) {
          const reader = new FileReader();
          //fileReaders.push(reader);
          reader.onload = (e) => {
            const { result } = e.target;

            if (result) {
              previewFiles = previewFiles.concat(result);
            }
            if (previewFiles.length === fieldValue.length) {
              setArrayImgPreview(previewFiles);
            }
          };
          reader.readAsDataURL(file);

          setCount(count + 1);
        }
      }
      if (idParametro === 14 && typeof fieldValue === "object") {
        setCount(fieldValue.length);
      }

      if (idParametro === 6 && typeof fieldValue === "object") {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setImgPreview(reader.result);
            // dispatch(addPreview({ reader: reader.result, indice: indice }));
          }
        };
        reader.readAsDataURL(fieldValue);
      }
    }
  }, [text, dni, file, offlineStore, online]);

  switch (idParametro) {
    case 1:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id}>{fieldLabel}</label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={
              estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                ? true
                : !fieldEditable
            }
            value={fieldValue}
            error={
              mandatory === null || text === "" ? fieldMandatory : mandatory
            }
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            onChange={handleChangeText}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </div>
      );

    case 11:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={
              estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                ? true
                : !fieldEditable
            }
            error={
              mandatory === null || dni === "" ? fieldMandatory : mandatory
            }
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            onChange={handleDni}
            value={fieldValue}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </div>
      );

    case 12:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={
              estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                ? true
                : !fieldEditable
            }
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            inputProps={{ inputMode: "email" }}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={fieldValue}
            onChange={handleChangeText}
          />
        </div>
      );

    case 3:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            label={fieldPlaceholder}
            disabled={
              estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                ? true
                : !fieldEditable
            }
            value={fieldValue}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            multiline
            rows={4}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleChangeText}
          />
        </div>
      );

    case 8:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            label={fieldPlaceholder}
            disabled={
              estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                ? true
                : !fieldEditable
            }
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            size="small"
            variant="filled"
            value={fieldValue}
            onChange={handleChangeText}
          />
        </div>
      );

    case 4:
      return (
        <div className="input-field">
          {fileDb?.uuidFileName === null ? (
            <>
              <div style={styleLabel}>
                <label htmlFor={field_id} style={styleLabel}>
                  {fieldLabel}
                </label>
              </div>
              <IconButton
                color="primary"
                aria-label="upload Archive"
                component="label"
              >
                <input
                  id="file"
                  style={{ width: "200px" }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/pdf,.doc, .docx,handleFile"
                  type="file"
                  onChange={handleFile}
                  disabled={
                    estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                      ? true
                      : !fieldEditable
                  }
                />

                <FolderIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton>
                <a
                  href={`  ${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${fileDb.uuidFileName}?${process.env.REACT_APP_AZURE_SAS}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ textDecoration: "none" }}
                >
                  <CloudDownloadIcon />
                </a>
              </IconButton>
              1 Archivo
            </>
          )}
        </div>
      );

    case 6:
      return (
        <div className="input-field">
          {fileDb?.uuidFileName == null ? (
            <>
              <div style={styleLabel}>
                <label htmlFor={field_id} style={styleLabel}>
                  {fieldLabel}
                </label>
              </div>

              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  accept="image/png,image/jpeg"
                  style={{ width: "200px" }}
                  type="file"
                  disabled={
                    estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                      ? true
                      : !fieldEditable
                  }
                  onChange={handleFile}
                />

                <ImageIcon />
              </IconButton>
            </>
          ) : (
            <>
              <div style={styleLabel}>
                <label htmlFor={field_id} style={styleLabel}>
                  {fieldLabel}
                </label>
              </div>
              <div style={{ display: "flex" }}>
                <IconButton variant="outlined">
                  <a
                    href={`${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${fileDb.uuidFileName}?${process.env.REACT_APP_AZURE_SAS}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ textDecoration: "none" }}
                  >
                    <CloudDownloadIcon />
                  </a>
                </IconButton>
                <ModalImg
                  imgAzure={`${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${fileDb.uuidFileName}?${process.env.REACT_APP_AZURE_SAS}`}
                  nameImg={fileDb.uuidFileName}
                />
              </div>
            </>
          )}
          <div className="content_img">
            <div className="content-img-image">
              <img
                style={{ height: "10rem" }}
                src={
                  fileDb.uuidFileName
                    ? ` ${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${fileDb.uuidFileName}?${process.env.REACT_APP_AZURE_SAS}`
                    : imgPreview
                }
                alt="Inspeeciones"
              />
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            variant={"standard"}
            InputLabelProps={{ shrink: true }}
            label={fieldPlaceholder}
            disabled={
              estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                ? true
                : !fieldEditable
            }
            value={fieldValue}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            type="number"
            onChange={handleChangeText}
          />
        </div>
      );

    case 5:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <TextField
            id={field_id}
            label={fieldPlaceholder}
            disabled={
              estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                ? true
                : !fieldEditable
            }
            value={fieldValue}
            error={mandatory === null ? fieldMandatory : mandatory}
            helperText={mandatory ? "Este Campo es Obligatorio" : ""}
            InputLabelProps={{ shrink: true }}
            type="date"
            onChange={handleChangeText}
          />
        </div>
      );

    case 10:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id={field_id}>{fieldPlaceholder}</InputLabel>
            <Select
              labelId={field_id}
              label={fieldPlaceholder}
              disabled={
                estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                  ? true
                  : !fieldEditable
              }
              value={fieldValue}
              error={mandatory === null ? fieldMandatory : mandatory}
              onChange={handleChangeSelect}
            >
              <MenuItem value="" disabled selected>
                Seleccionar...
              </MenuItem>
              {JSON.parse(fieldOptions)?.map((el, i) => (
                <MenuItem value={el} key={i + "option"}>
                  {el}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {mandatory ? "Este Campo es Obligatorio" : ""}
            </FormHelperText>
          </FormControl>
        </div>
      );

    case 9:
      return (
        <div className="input-field">
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>No</Typography>
            <AntSwitch
              onChange={hanleSwitch}
              checked={fieldValue === "true" ? true : false}
              disabled={
                estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                  ? true
                  : !fieldEditable
              }
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>Si</Typography>
          </Stack>
        </div>
      );

    case 13:
      return (
        <>
          {fileDb?.uuidFileName ? (
            <div>
              <div style={styleLabel}>
                <label style={styleLabel}>{fieldLabel}</label>
              </div>
              <Box
                sx={{
                  height: 300,
                  border: "solid 1px black",
                  borderRadius: "0.5rem",
                  p: 1,
                  overflowY: "scroll",
                }}
              >
                <ImageList cols={1} gap={20}>
                  {fileDb.uuidFileName.split(",").map((item) => (
                    <ImageListItem key={item} cols={1}>
                      <div>
                        <div style={{ display: "flex" }}>
                          <IconButton
                            variant="outlined"
                            style={{ display: "inline-block" }}
                          >
                            <a
                              href={`${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${item}?${process.env.REACT_APP_AZURE_SAS}`}
                              target="_blank"
                              rel="noreferrer noopener"
                              style={{ textDecoration: "none" }}
                            >
                              <CloudDownloadIcon />
                            </a>
                          </IconButton>
                          <ModalImg
                            imgAzure={`${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${item}?${process.env.REACT_APP_AZURE_SAS}`}
                            nameImg={item}
                          />
                        </div>
                        <img
                          src={`${process.env.REACT_APP_ENPOINT_AZURE_LINK}/${item}?${process.env.REACT_APP_AZURE_SAS}`}
                          alt={item}
                          style={{
                            width: "100%",
                            border: "1px solid #000",
                          }}
                        />
                      </div>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            </div>
          ) : (
            <div>
              <div style={styleLabel}>
                <label style={styleLabel}>{fieldLabel}</label>
              </div>
              <Button
                variant="contained"
                size="small"
                component="label"
                style={{ marginBottom: "0.8rem" }}
                disabled={
                  estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                    ? true
                    : !fieldEditable
                }
              >
                <input
                  accept="image/*"
                  style={{ width: "200px" }}
                  type="file"
                  hidden
                  multiple
                  onChange={async (e) => await handleFiles(e)}
                />
                Subir Imagenes
              </Button>
              <Box
                sx={{
                  height: 300,
                  border: "solid 1px black",
                  borderRadius: "0.5rem",
                  p: 1,
                  overflowY: "scroll",
                }}
              >
                <ImageList variant="masonry" cols={1} gap={8}>
                  {arrayImgPreview?.map((item) => (
                    <ImageListItem key={item}>
                      <div>
                        <img src={item} alt={item} style={{ width: "100%" }} />
                      </div>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            </div>
          )}
        </>
      );

    case 14:
      return (
        <>
          {fileDb?.uuidFileName ? (
            <>
              <div style={styleLabel}>
                <label htmlFor={field_id} style={styleLabel}>
                  {fieldLabel}
                </label>
              </div>
              <Button
                onClick={async () =>
                  await handleDowloadFile(fileDb.uuidFileName, fileDb.fileName)
                }
              >
                Descargar
              </Button>
              <span> {fileDb.uuidFileName?.split(",").length} Archivos</span>
            </>
          ) : (
            <div>
              <div style={styleLabel}>
                <label htmlFor={field_id} style={styleLabel}>
                  {fieldLabel}
                </label>
              </div>
              <Button
                variant="contained"
                size="small"
                component="label"
                style={{ marginBottom: "0.8rem" }}
                disabled={
                  estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                    ? true
                    : !fieldEditable
                }
              >
                <input
                  id="file"
                  disabled={!fieldEditable}
                  style={{ width: "200px" }}
                  multiple
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/pdf,.doc, .docx,handleFile"
                  type="file"
                  onChange={async (e) => await handleArchives(e)}
                  hidden
                />
                Subir Archivo
              </Button>
              <span> {`${count} - Archivos`}</span>
            </div>
          )}
        </>
      );

    case 15:
      return (
        <div>
          <div style={styleLabel}>
            <label htmlFor={field_id} style={styleLabel}>
              {fieldLabel}
            </label>
          </div>
          <FormControl sx={{ m: 1, minWidth: 220 }}>
            <InputLabel id={field_id}>{fieldPlaceholder}</InputLabel>
            <Select
              labelId={field_id}
              label={fieldPlaceholder}
              disabled={
                estado === "ATENDIDA" || estado === "EN PROGRAMACIÓN"
                  ? true
                  : !fieldEditable
              }
              error={mandatory === null ? fieldMandatory : mandatory}
              multiple
              value={fieldValue ? fieldValue.split(",") : []}
              onChange={handleMultiple}
              renderValue={(selected) => selected.join(", ")}
            >
              {JSON.parse(fieldOptions)?.map((el, i) => (
                <MenuItem value={el} key={i + "option"}>
                  <Checkbox checked={fieldValue?.split(",").indexOf(el) > -1} />
                  <ListItemText primary={el} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );

    default:
      return <div>no existe parametro</div>;
  }
};

export default ElementTechnical;
