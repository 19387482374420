import { createSlice } from "@reduxjs/toolkit";
import {
  sendFormat,
  sendData,
  getFormat,
  updateData,
} from "./formatReducer.action";

const initialState = {
  formato: {
    cliente: null,
    nombre: "",
    secciones: [],
  },
  idFormatos: [], //when update data
  error: null,
  loading: false,
  success: false,
  loadingFormat: false,
};

export const formatReducer = createSlice({
  name: "formato",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.success = false;
    },
    clearErrorAction: (state) => {
      state.error = null;
    },
    addSection: (state, action) => {
      state.formato.secciones.push(action.payload);
    },
    addGroup: (state, action) => {
      const { nombre, parametros, indice } = action.payload;

      state.formato.secciones[indice].grupos.push({ nombre, parametros });
    },
    addParameters: (state, action) => {
      const { indice, field, indiceSection } = action.payload;

      state.formato.secciones[indiceSection].grupos[indice].parametros.push(
        field
      );
    },
    addField: (state, action) => {
      const {
        field,
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
      } = action.payload;
      //Arreglar logica
      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = field;
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = field;
      }
    },
    addMyclient: (state, action) => {
      state.formato.cliente = action.payload; 
    },
    deleteField: (state, action) => {
      const { indiceGroup, indiceSection, indiceParams, indiceSubcampo, id } =
        action.payload;

      if (indiceSubcampo === undefined) {
        if (id) {
          if (
            state.formato.secciones[indiceSection].grupos[indiceGroup]
              .parametros[indiceParams].fieldValue === "{}"
          ) {
            const arrFileDelete =
              state.formato.secciones[indiceSection].grupos[
                indiceGroup
              ].parametros[indiceParams].fileDb.uuidFileName.split(",");

            state.idFormatos.push({ id, arrFileDelete });
          } else {
            state.idFormatos.push({ id });
          }
        }

        state.formato.secciones[indiceSection].grupos[
          indiceGroup
        ].parametros.splice(indiceParams, 1);
      } else {
        if (id) {
          if (
            state.formato.secciones[indiceSection].grupos[indiceGroup]
              .parametros[indiceParams].subCampos[indiceSubcampo].fieldValue ===
            "{}"
          ) {
            const arrFileDelete =
              state.formato.secciones[indiceSection].grupos[
                indiceGroup
              ].parametros[indiceParams].subCampos[
                indiceSubcampo
              ].fileDb.uuidFileName.split(",");

            state.idFormatos.push({ id, arrFileDelete });
          } else {
            state.idFormatos.push({ id });
          }
        }

        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos.splice(indiceSubcampo, 1);
      }
    },
    deleteGroup: (state, action) => {
      const { indiceGroup, indiceSection, grupo } = action.payload;
      grupo.parametros.forEach((param) => {
        if (param.id) {
          if (param.fieldValue === "{}") {
            const arrFileDelete = param.fileDb.uuidFileName.split(",");

            state.idFormatos.push({ id: param.id, arrFileDelete });
          } else {
            state.idFormatos.push({ id: param.id });
          }
        }
      });

      state.formato.secciones[indiceSection].grupos.splice(indiceGroup, 1);
    },
    deleteSection: (state, action) => {
      const { indice, grupos } = action.payload;

      grupos.forEach((grupo) => {
        if (grupo.parametros.length > 0) {
          grupo.parametros.forEach((param) => {
            if (param.id) {
              if (param.fieldValue === "{}") {
                const arrFileDelete = param.fileDb.uuidFileName.split(",");

                state.idFormatos.push({ id: param.id, arrFileDelete });
              } else {
                state.idFormatos.push({ id: param.id });
              }
            }
          });
        }
      });

      state.formato.secciones.splice(indice, 1);
    },
    updateField: (state, action) => {
      const { indiceGroup, indiceSection, indiceParams, indiceSubcampo, text } =
        action.payload;

      let dataField = text;
      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          fieldValue: dataField,
          indiceSection,
          indiceParams,
          indiceGroup,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          fieldValue: dataField,
          indiceSection,
          indiceParams,
          indiceGroup,
          indiceSubcampo,
        };
      }
    },

    updatePropEdit: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        fieldEditable,
      } = action.payload;
      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          fieldEditable,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          fieldEditable,
        };
      }
    },
    updatePropMandatory: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        fieldMandatory,
      } = action.payload;
      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          fieldMandatory,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          fieldMandatory,
        };
      }
    },
    updatePropVisible: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        fieldVisible,
      } = action.payload;

      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          fieldVisible,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          fieldVisible,
        };
      }
    },
    //Diego
    updatePropLabel: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        fieldLabel,
      } = action.payload;

      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          fieldLabel,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          fieldLabel,
        };
      }
    },

    updatePropPlaceholder: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        fieldPlaceholder,
      } = action.payload;
      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          fieldPlaceholder,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          fieldPlaceholder,
        };
      }
    },
    updatePropPosition: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        fieldRow,
        fieldColumn,
      } = action.payload;
      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          fieldColumn,
          fieldRow,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          fieldColumn,
          fieldRow,
        };
      }
    },

    updateObservacion: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        observacion,
      } = action.payload;

      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
          observacion,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
          observacion,
        };
      }
    },

    updateCorrectivo: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        requiereCorrectivo,
      } = action.payload;

      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
            requiereCorrectivo,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
            requiereCorrectivo,
        };
      }
    },
    
    updateRequiereAdjuntos: (state, action) => {
      const {
        indiceGroup,
        indiceSection,
        indiceParams,
        indiceSubcampo,
        requiereAdjuntos,
      } = action.payload;

      if (indiceSubcampo === undefined) {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams],
            requiereAdjuntos,
        };
      } else {
        state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
          indiceParams
        ].subCampos[indiceSubcampo] = {
          ...state.formato.secciones[indiceSection].grupos[indiceGroup]
            .parametros[indiceParams].subCampos[indiceSubcampo],
            requiereAdjuntos,
        };
      }
    },
    

    updatesubCampo: (state, action) => {
      const { indiceGroup, indiceSection, indiceParams, subCampos } =
        action.payload;
      state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
        indiceParams
      ].subCampos.push(subCampos);
    },
    updateTextObserver: (state, action) => {
      const { indiceGroup, indiceSection, indiceParams, textoObservacion } =
        action.payload;
      state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
        indiceParams
      ] = {
        ...state.formato.secciones[indiceSection].grupos[indiceGroup]
          .parametros[indiceParams],
        textoObservacion,
      };
    },

    addNameTemplate: (state, action) => {
      const { template } = action.payload;
      state.formato.nombre = template;
    },

    addNameSection: (state, action) => {
      const { nameSection, indiceSection } = action.payload;

      state.formato.secciones[indiceSection].nombre = nameSection;
    },
    addNameGroup: (state, action) => {
      const { indiceSection, indiceGroup, nameGroup } = action.payload;
      state.formato.secciones[indiceSection].grupos[indiceGroup].nombre =
        nameGroup;
    },
    clearFormat: (state, action) => {
      state.formato = {
        nombre: "",
        secciones: [],
        cliente : JSON.parse(localStorage.getItem("jsonCliente")),
      };
    },
    restoreMyClient: (state, action) => {
      state.formato.cliente = JSON.parse(localStorage.getItem("jsonCliente"));
    },
    clearIdParams: (state, action) => {
      state.idFormatos = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendFormat.fulfilled, (state, action) => {
        state.loadingFormat = true;
        action.payload.forEach((elemento) => {
          const {
            fileDb,
            indiceSection,
            indiceGroup,
            indiceParams,
            indiceSubcampo,
            fieldValue,
          } = elemento;

          if (indiceSection !== undefined) {
            if (indiceSubcampo === undefined) {
              state.formato.secciones[indiceSection].grupos[
                indiceGroup
              ].parametros[indiceParams].fileDb = fileDb;

              if (typeof fieldValue === "object") {
                state.formato.secciones[indiceSection].grupos[
                  indiceGroup
                ].parametros[indiceParams].fieldValue = "{}";
              }
            } else {
              state.formato.secciones[indiceSection].grupos[
                indiceGroup
              ].parametros[indiceParams].subCampos[indiceSubcampo].fileDb = fileDb;

              if (typeof fieldValue === "object") {
                state.formato.secciones[indiceSection].grupos[
                  indiceGroup
                ].parametros[indiceParams].subCampos[
                  indiceSubcampo
                ].fieldValue = "{}";
              }
            }
          }
        });
      })
      .addCase(sendFormat.pending, (state, action) => {
        state.loadingFormat = true;
      });
    builder
      .addCase(sendData.pending, (state) => {
        state.loadingFormat = true;
      })
      .addCase(sendData.fulfilled, (state, action) => {
        state.loadingFormat = false;
        state.success = action.payload;
      })
      .addCase(sendData.rejected, (state, action) => {
        state.loadingFormat = false;

        state.error = action.error.message;
      });
    builder
      .addCase(updateData.pending, (state) => {
        state.loadingFormat = true;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        state.loadingFormat = false;
        state.success = action.payload;
      })
      .addCase(updateData.rejected, (state, action) => {
        state.loadingFormat = false;

        state.error = action.error.message;
      });
    builder
      .addCase(getFormat.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFormat.fulfilled, (state, action) => {
        state.loading = false;
        state.formato = { ...action.payload };
      })
      .addCase(getFormat.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      });
  },
});
export const {
  clearSuccess,
  clearErrorAction,
  addSection,
  addGroup,
  addParameters,
  addField,
  deleteField,
  deleteGroup,
  deleteSection,
  addInputs,
  updateField,
  updateArrayField,
  addNameTemplate,
  addNameSection,
  addNameGroup,
  updatePropEdit,
  updatePropMandatory,
  updatePropVisible,
  updatePropLabel,
  updatePropPlaceholder,
  updatePropPosition,
  clearFormat,
  clearIdParams,
  updateObservacion,
  updateCorrectivo,
  updateRequiereAdjuntos,
  updateTextObserver,
  restoreMyClient,
  addMyclient,
  updatesubCampo,
} = formatReducer.actions;
export default formatReducer.reducer;
