import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { dowloadFile } from "../../../../../../../helpers/azure/azureStorage";
import { useState } from "react";
import { Button } from "@mui/material";
import { updateRquiereAdjuntos } from "../../../../../../../store/menuGroupReducer/menuGroupReducer.reducer";

const RequiereAdjuntos = ({ field, indice }) => {
  const dispatch = useDispatch();
  const [countArchivosAdjuntos, setCountArchivosAdjuntos] = useState(0);

  const handleDowloadFile = async (uuidfile, fileName) => {
    const arr = uuidfile.split(",");
    const arrName = fileName.split(",");

    const array = arr.map((item, index) => ({
      file: item,
      name: arrName[index],
    }));

    for await (const element of array) {
      let file = await dowloadFile(element.file);
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", element.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleArchives = async (e) => {
    const countfilesadjuntos = e.target.files.length;
    setCountArchivosAdjuntos(countfilesadjuntos);

    let arrayArchives = [];

    const files = e.target.files;
    //aceptar solo 2 o mas archivos

    for await (const file of files) {
      arrayArchives.push(file);
    }
    dispatch(
      updateRquiereAdjuntos({
        ...indice,
        arrayAdjuntos: arrayArchives,
        uuidFileNamePregunta: null,
        fileNamePregunta: null,
        requiereAdjuntosPregunta: true,
      })
    );
  };

  return (
    <div>
      <>
        {field?.uuidFileNamePregunta ? (
          <>
            <IconButton
              color="primary"
              aria-label="upload Archive"
              component="label"
              onClick={async () =>
                await handleDowloadFile(
                  field?.uuidFileNamePregunta,
                  field.fileNamePregunta
                )
              }
            >
              <AttachFileIcon /> Descargar
            </IconButton>

            <span>
              {" "}
              {field.uuidFileNamePregunta?.split(",").length} Archivos
            </span>
          </>
        ) : (
          <div>
            <IconButton
              color="primary"
              aria-label="upload Archive"
              component="label"
            >
              <input
                id="file"
                multiple
                hidden
                style={{ width: "200px" }}
                type="file"
                onChange={async (e) => await handleArchives(e)}
              />
              <AttachFileIcon /> {countArchivosAdjuntos}
            </IconButton>
          </div>
        )}
      </>
    </div>
  );
};

export default RequiereAdjuntos;
