import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePropEdit,
  updatePropMandatory,
  updatePropVisible,
} from "../../../store/formatReducer/formatoReducer.reducer";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuItem, Menu } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import PropLabel from "./components/propLabel/PropLabel";
import PropPlaceholder from "./components/propPlaceholder/PropPlaceholder";
import PropPosition from "./components/propPosition/PropPosition";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};
const PropMenu = ({ listName, indice, id }) => {
  const dispatch = useDispatch();
  const { formato } = useSelector((state) => state.formato);

  const [anchorEl, setAnchorEl] = useState(null);


  let propiedades =
  indice.indiceSubcampo === undefined
    ? formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
        .parametros[indice.indiceParams]
    : formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
        .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo];


  const { fieldEditable, fieldMandatory, fieldVisible } = propiedades;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = (e) => {
    dispatch(
      updatePropEdit({
        ...indice,
        fieldEditable: e.target.checked,
      })
    );
  };
  const handleOblig = (e) => {
    dispatch(
      updatePropMandatory({
        ...indice,
        fieldMandatory: e.target.checked,
      })
    );
  };
  const handleVisible = (e) => {
    dispatch(
      updatePropVisible({
        ...indice,
        fieldVisible: e.target.checked,
      })
    );
  };

  return (
    <>
      <MenuItem
        aria-controls={open ? "basic-menu3" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ width: "none", minWidth: "none" }}
      >
        {listName}
        {open ? <ArrowRightIcon /> : <ArrowDropUpIcon />}
      </MenuItem>

      <Menu
        id="basic-menu3"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...popoverPropiert}
      >
        <MenuItem>
          <Checkbox onChange={handleEdit} checked={fieldEditable} /> Editable
        </MenuItem>
        <MenuItem>
          <Checkbox onChange={handleOblig} checked={fieldMandatory} />
          Obligatrio
        </MenuItem>
        <MenuItem>
          <Checkbox onChange={handleVisible} checked={fieldVisible} />
          Visible
        </MenuItem>

        <PropLabel indice={indice} />

        <PropPlaceholder indice={indice} />
        <PropPosition indice={indice} />
      </Menu>
    </>
  );
};

export default PropMenu;
