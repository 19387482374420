import React, { useState } from "react";
import { content_paper, content_add } from "./styleSelect";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuItem, Menu, TextField, Paper, Button, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../store/formatReducer/formatoReducer.reducer";
import { useEffect } from "react";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const SelectList = ({ name, indice, id, multiple }) => {
  const [chipData, setChipData] = useState([]);
  const [textChip, setTextChip] = useState("");
  const [anchorEl, setAnchorEl] = useState();
  const [auxBol, setAuxBol] = useState(true);
  const dispatch = useDispatch();
  const { formato } = useSelector((state) => state.formato);

  let propiedades =
    indice.indiceSubcampo === undefined
      ? formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams]
      : formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo];

  const {
    observacion,
    requiereCorrectivo,
requiereAdjuntos,
    fieldOptions,
    fieldLabel,
    fieldPlaceholder,
    fieldMandatory,
    fieldEditable,
    fieldVisible,
    fieldColumn,
    fieldRow,
    fileDb,
  } = propiedades;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = (chipToDelete, index) => {
    let auxArray = chipData;
    auxArray.splice(index, 1);

    setChipData([...auxArray]);
  };

  const addChip = () => {
    if (textChip === "") return;
    setChipData([...chipData, textChip]);
    setTextChip("");
  };
  const onKeyDown = (e) => {
    e.stopPropagation();
  };
  const handleKeyPress = (e) => {
    if (e.target.value === "") return;
    if (e.key === "Enter") {
      setChipData([...chipData, e.target.value]);
      setTextChip("");
    }
    return;
  };
  const handleCancel = () => {
    setChipData([]);
    setAnchorEl(null);
  };

  if (auxBol && fieldOptions !== undefined && fieldOptions?.length !== 0) {
    setAuxBol(false);

    setChipData(JSON.parse(fieldOptions));
  }

  const handleAcept = () => {
    if (multiple === false) {
      dispatch(
        addField({
          indiceSection: indice.indiceSection,
          indiceGroup: indice.indiceGroup,
          indiceParams: indice.indiceParams,
          indiceSubcampo: indice.indiceSubcampo,
          field: {
            indiceSection: indice.indiceSection,
            indiceGroup: indice.indiceGroup,
            indiceParams: indice.indiceParams,
            id,
            idParametro: 10,
            fileDb,
            field_id: "selección",
            fieldLabel: fieldLabel,
            fieldMandatory: fieldMandatory,
            fieldEditable: fieldEditable,
            fieldColumn: fieldColumn,
            fieldRow: fieldRow,
            fieldPlaceholder: fieldPlaceholder,
            fieldVisible: fieldVisible,
            fieldType: "select",
            fieldValue: "",
            fieldOptions: JSON.stringify(chipData),
            observacion,
            requiereCorrectivo,
requiereAdjuntos,
            subCampos: [],
          },
        })
      );
    } else {
      dispatch(
        addField({
          indiceSection: indice.indiceSection,
          indiceGroup: indice.indiceGroup,
          indiceParams: indice.indiceParams,
          indiceSubcampo: indice.indiceSubcampo,
          field: {
            indiceSection: indice.indiceSection,
            indiceGroup: indice.indiceGroup,
            indiceParams: indice.indiceParams,
            id,
            idParametro: 15,
            fileDb,
            field_id: "seleccionmultiple",
            fieldLabel: fieldLabel,
            fieldMandatory: fieldMandatory,
            fieldEditable: fieldEditable,
            fieldColumn: fieldColumn,
            fieldRow: fieldRow,
            fieldPlaceholder: fieldPlaceholder,
            fieldVisible: fieldVisible,
            fieldType: "select",
            fieldValue: "",
            fieldOptions: JSON.stringify(chipData),
            observacion,
            requiereCorrectivo,
requiereAdjuntos,
            subCampos: [],
          },
        })
      );
    }

    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        aria-controls={open ? "basic-menu3" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ width: "none", minWidth: "none" }}
      >
        {name}
        {open ? <ArrowRightIcon /> : <ArrowDropUpIcon />}
      </MenuItem>

      <Menu
        id="basic-menu3"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...popoverPropiert}
      >
        <Paper sx={content_paper} component="ul">
          {chipData?.map((data, index) => (
            <ListItem key={index + "chip"}>
              <Chip label={data} onDelete={() => handleDelete(data, index)} />
            </ListItem>
          ))}
        </Paper>
        <div style={content_add}>
          <TextField
            variant="standard"
            label="Ingrese Campo"
            size="small"
            onKeyDown={onKeyDown}
            onChange={(e) => setTextChip(e.target.value)}
            onKeyPress={handleKeyPress}
            value={textChip}
            type="text"
          />
          <Button
            size="small"
            color="primary"
            onClick={addChip}
            variant="contained"
            sx={{
              borderRadius: "100%",
              width: "2.8rem",
              height: "2.8rem",
              minWidth: "2.8rem",
              margin: "0.8rem",
            }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                lineHeight: "1rem",
                fontWeight: "bold",
              }}
            >
              +
            </span>
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "1.5rem",
          }}
        >
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button variant="outlined" color="primary" onClick={handleAcept}>
            Aceptar
          </Button>
        </div>
      </Menu>
    </>
  );
};

export default SelectList;
