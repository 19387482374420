import { useDispatch } from "react-redux";
import {
  sendFormat,
  updateData,
} from "../../../../../../store/formatReducer/formatReducer.action";
import { useParams } from "react-router";

import { Button } from "@mui/material";

const FooterModal = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const { idFormat } = useParams();
  const handleSend = () =>
    Promise.resolve(
      dispatch(sendFormat()).then(() => dispatch(updateData({ idFormat })))
    );

  return (
    <div className="button-format_modal">
      <h2 id="parent-modal-title">Actualizar Formato</h2>
      <p id="parent-modal-description">
        ¿Usted desea actualizar el formato a la plataforma?
      </p>

      <div className="content-btn">
        <Button
          onClick={async () => await handleSend()}
          variant="contained"
          size="small"
        >
          Confirmar
        </Button>
        <Button
          color="error"
          variant="contained"
          size="small"
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};
export default FooterModal;
