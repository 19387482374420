import { createAsyncThunk } from "@reduxjs/toolkit";

import { getInspectionAsync,postAnularAsync } from "../../api/inspection";

export const getTemplate = createAsyncThunk(
  "trayInspection/getTemplate",
  async (dataParams, state) => {
    try {
      const { data } = await getInspectionAsync({
        id: dataParams.idUser,
        idCliente: dataParams.idCliente,
        filtroText: dataParams.filtro,
        filtroCodigo : dataParams.filtroCodigo,
        filtroInmueble : dataParams.filtroInmueble,
        filtroResponsable : dataParams.filtroResponsable,
        filtroFormato : dataParams.filtroFormato,
        filtroEstado : dataParams.filtroEstado,
        filtroOffline : dataParams.filtroOffline,
      });

      return data;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);

export const postAnular = createAsyncThunk(
  "trayInspection/postAnular",
  async (dataParams, state) => {
    
    try {
      const { data } = await postAnularAsync(
        dataParams.idInspecion
      );

      return data;
    } catch (err) {
      let errorData =
        err.response?.data?.message || err.response?.data?.title || err.message;
      if (!errorData) {
        errorData = "Something went wrong";
      }
      throw Error(errorData);
    }
  }
);