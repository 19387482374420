import { createSlice } from "@reduxjs/toolkit";
import {
  getTechnicalFormat,
  finishInspect,
  addFechaInit,
  anularInspeccion,
} from "./technicalFormatReducer.action";
import { addRecomendacion } from "../menuGroupReducer/menuGroupReducer.reducer";

const initialState = {
  message: "",
  loadingButton: false,
  formato: {},
  preview: [],
  listPreview: [],
  success: false,
  loadingFormat: false,
  error: null,
};

export const technicalFormatReducer = createSlice({
  name: "technicalFormato",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.success = false;
    },
    clearErrorAction: (state) => {
      state.error = null;
    },
    clearFormat: (state) => {
      state.formato = {};
    },
    addListPreview: (state, action) => {
      state.listPreview = action.payload;
    },
    addPreview: (state, action) => {
      state.preview = [...state.preview, action.payload];
    },
    clearPreview: (state) => {
      state.preview = "";
    },
    clearListPreview: (state) => {
      state.listPreview = [];
    },

    updateField: (state, action) => {
      const { indiceGroup, indiceSection, indiceParams, text } = action.payload;

      let dataField = text;

      state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
        indiceParams
      ] = {
        ...state.formato.secciones[indiceSection].grupos[indiceGroup]
          .parametros[indiceParams],
        fieldValue: dataField,
        indiceSection,
        indiceParams,
        indiceGroup,
      };
    },
    updateTextObserver: (state, action) => {
      const { indiceGroup, indiceSection, indiceParams, textoObservacion } =
        action.payload;
      state.formato.secciones[indiceSection].grupos[indiceGroup].parametros[
        indiceParams
      ] = {
        ...state.formato.secciones[indiceSection].grupos[indiceGroup]
          .parametros[indiceParams],
        textoObservacion,
      };
    },

    addStartTime: (state, action) => {
      state.formato.horaFin = action.payload;
    },
    addStartDate: (state, action) => {
      state.formato.fechaFin = action.payload;
    },
    addConclusionFinalizacionInspeccion: (state, action) => {
      state.formato.textoConclusiones = action.payload;
    },
    addRecomendacionFinalizacionInspeccion: (state, action) => {
      state.formato.textoRecomendacion = action.payload;
    },
    getTechnicalFormatOffline: (state, action) => {
      state.formato = action.payload;
    },
    addFechaInitOffline: (state, action) => {
      state.formato.fechaInicioReal = action.payload.fechaInicioReal;
      state.formato.estado = action.payload.estado;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTechnicalFormat.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTechnicalFormat.fulfilled, (state, action) => {
        state.loading = false;
        state.formato = action.payload;
      })
      .addCase(getTechnicalFormat.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      });

    builder
      .addCase(finishInspect.pending, (state) => {
        state.loadingFormat = true;
      })
      .addCase(finishInspect.fulfilled, (state, action) => {
        state.loadingFormat = false;
        state.formato.fechaFin = action.payload.fechaFin;
        state.formato.horaFin = action.payload.horaFin;
        state.formato.estado = action.payload.estado;
        state.success = true;
      })
      .addCase(finishInspect.rejected, (state, action) => {
        state.loadingFormat = false;

        state.error = action.error.message;
      });

      builder
      .addCase(anularInspeccion.pending, (state) => {
        state.loadingFormat = true;
      })
      .addCase(anularInspeccion.fulfilled, (state, action) => {
        state.loadingFormat = false;
        state.success = true;
      })
      .addCase(anularInspeccion.rejected, (state, action) => {
        state.loadingFormat = false;

        state.error = action.error.message;
      });
      

    builder
      .addCase(addFechaInit.pending, (state, action) => {
        state.loadingButton = true;
      })
      .addCase(addFechaInit.fulfilled, (state, action) => {
        state.loadingButton = false;
        state.formato.fechaInicioReal = action.payload.fechaInicioReal;
        state.formato.estado = action.payload.estado;
      })
      .addCase(addFechaInit.rejected, (state, action) => {
        state.loadingButton = false;
      });
  },
});

export const {
  updateField,
  clearSuccess,
  clearErrorAction,
  clearFormat,
  addStartTime,
  addStartDate,
  addListPreview,
  updateTextObserver,
  addPreview,
  clearPreview,
  clearListPreview,
  getTechnicalFormatOffline,
  addFechaInitOffline,
  addConclusionFinalizacionInspeccion,
  addRecomendacionFinalizacionInspeccion,
} = technicalFormatReducer.actions;

export default technicalFormatReducer.reducer;
