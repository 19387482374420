import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePropPlaceholder } from "../../../../../store/formatReducer/formatoReducer.reducer";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuItem, Menu, TextField, Button } from "@mui/material";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};
const PropPlaceholder = ({ indice }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [placeholder, setPlaceholder] = useState();
  const { formato } = useSelector((state) => state.formato);
  const dispatch = useDispatch();
  const { fieldPlaceholder } =
    formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
      .parametros[indice.indiceParams];

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSend = () => {
    dispatch(
      updatePropPlaceholder({ ...indice, fieldPlaceholder: placeholder })
    );
    setAnchorEl(null);
  };
  const onKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <MenuItem
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ width: "none", minWidth: "none" }}
      >
        Placeholder
        {open ? <ArrowRightIcon /> : <ArrowDropUpIcon />}
      </MenuItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...popoverPropiert}
      >
        <div style={{ margin: "1.2rem" }}>
          <TextField
            size="small"
            variant={"standard"}
            defaultValue={fieldPlaceholder}
            onKeyDown={onKeyDown}
            onChange={(e) => setPlaceholder(e.target.value)}
          />
          <Button
            type="button"
            size="small"
            variant="contained"
            style={{ marginLeft: "0.8rem" }}
            onClick={handleSend}
          >
            Guardar
          </Button>
        </div>
      </Menu>
    </>
  );
};

export default PropPlaceholder;
