import ax from "../../helpers/axiosConfig";

export const postReport = (data) => {
  const option = { responseType: "blob" };
  return ax.post(`/api/ReporteInspeccion/ExportarInspeccion`, data, option);
};

export const ExportReport = (data) => {
  return ax.get(`/api/ExportTable/GenerarInforme/${data}`);
};

export const ExportInforme = (data) => {
  return ax.get(`/api/InformeInspecciones/GenerarInforme/${data}`);
};

