import ax from "../../helpers/axiosConfig";


// const url2 = process.env.REACT_APP_TEST_ENPOINT;
export const postFormat = (data) => {
  const { dataFormato, idFormatos } = data;

  console.log(idFormatos);
  return ax.post(`/api/Core/GuardarFormato`, {
    ...dataFormato,
    idFormatos,
  });
};

export const getTemplate = (idCliente) => {
 
  return ax.post(`/api/Core/ObtenerFormatos`, {
    filter: { idCliente: idCliente },
    page: 0,
    pageSize: 100,
  });
};

export const getFormatAsync = (data) => {
  const { idFormat } = data;
  return ax.get(`/api/core/ObtenerFormato/${idFormat}`);
};

export const updateDataAsync = (data) => {
  const { idFormat, dataFormato } = data;

  return ax.put(`/api/core/ActualizarFormato/${idFormat}`, {
    ...dataFormato,
    activo: true,
  });
};

export const actualizarParamsAsync = (id) => {
  return ax.put(`/api/core/ActualizarParametro/${id}`);
};


