import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Period from "./components/period/Period";
import TechnicalSelect from "./components/technicalSelect/TechnicalSelect";
import SelectFormat from "./components/formatSelect/formatSelect";
import { getMyClient } from "../../../api/ediServices";
import { useSelector, useDispatch } from "react-redux";
import {
  addMyclient,
  restoreMyClient,
  clearRegisterInspection,
  updateComplete,
} from "../../../store/registerInspectReducer/registerInspectReducer.reducer";

import SendModal from "./components/sendModal/SendModal";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  InputLabel,
  Alert,
  Chip,
  Autocomplete,
  TextField,
  Divider,
  Breadcrumbs,
} from "@mui/material";
import "./ConfigInspection.css";

const steps = [
  {
    label: "Inmueble y Periodo",
    body: ({ cliente, setCliente }) => (
      <Period cliente={cliente} setCliente={setCliente} />
    ),
  },
  {
    label: "Plantilla y Técnicos",
    body: () => <TechnicalSelect />,
  },
  {
    label: "Resumen y Confirmación",

    body: () => <SelectFormat />,
  },
];

const styleLink = {
  margin: 0,

  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  display: "block",
  textDecoration: "none",
  color: "inherit",
};
const ConfigInspection = () => {
  const dispatch = useDispatch();
  const { complete, RegisterData, myClient } = useSelector(
    (state) => state.registerInspect
  );
  const { formato } = useSelector((state) => state.formato);
  const [cliente, setCliente] = useState(null); //for period
  const [client, setClient] = useState(null);
  const [Arrclient, setArrClient] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [aux, setAux] = useState(false); //for my-clients
  const clientelocalStorage = JSON.parse(localStorage.getItem("jsonCliente"));

  //
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Brayan
  // useEffect(() => {
  //   dispatch(clearRegisterInspection());
  // }, []);
  const handleNext = async () => {
    if (activeStep === 0) {
      setAux(true); //disabled my clients
    }
    if (activeStep === 2) {
      //active modal and dispatch
      handleOpen();
      //  await dispatch(postRegisterInspection());
      setActiveStep(2);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setAux(false); //disabled my clients
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setAux(false);
    setActiveStep(0);
    dispatch(clearRegisterInspection());
    setClient(null);
  };

  const getMyclientAsync = async () => {
    const res = await getMyClient();

    const { ListaEntidadComun } = JSON.parse(res);

    setArrClient(ListaEntidadComun);
  };

  useEffect(() => {
    getMyclientAsync();

    let arrValues = Object.keys(RegisterData);
    let ArrauxBol = arrValues.map((item) => {
      if (item === "startDate" || item === "startTime" || item === "tecnicos")
        return true;

      if (
        typeof RegisterData[item] === "object" &&
        RegisterData[item] !== null &&
        RegisterData[item].length !== 0
      ) {
        return true;
      }
      if (typeof RegisterData[item] === "string") {
        return true;
      }

      if (RegisterData[item] === null || RegisterData[item]?.length === 0)
        return false;
      return;
    });

    if (ArrauxBol.every((item) => item)) {
      dispatch(updateComplete({ complete: true }));
    }

    if (client !== null) {
      dispatch(addMyclient({ myClient: client }));
    }
  }, [RegisterData, client, myClient, cliente, complete, dispatch]);
  //client?.map((e) => e.Nombre)

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1.5rem" }}>
        <Link
          to="/"
          style={{
            color: "inherit",
            mouseHover: "pointer",
            textDecoration: "auto",
          }}
        >
          Inspecciones
        </Link>
        <Typography color="text.primary">
          Configuración de la inspección
        </Typography>
      </Breadcrumbs>
      <Box sx={{ maxWidth: 600, marginLeft: "3rem" }}>
        <div className="content-Inspection_title">
          <div>
            <h2>Registrar Plan de inspección:</h2>
          </div>
          <Chip color="primary" label="Nuevo" />
        </div>
        {/* <div className="content-clients">
          <InputLabel id="clientsInspection">Mis Clientes </InputLabel>
          <br />
          <Autocomplete
            disabled={aux}
            disablePortal
            value={myClient ? JSON.stringify(myClient) : null}
            id="clientsInspection"
            options={
              Arrclient
                ? Arrclient.map((e) =>
                    JSON.stringify({ label: e.Nombre, id: e.Id })
                  )
                : []
            }
            getOptionLabel={(option) => {
              let data = JSON.parse(option);
              return data.label;
            }}
            //OptionLabel={Arrclient ? Arrclient.map((e) => e.Nombre) : []}

            onChange={(event, newValue) => {
              if (newValue === null) {
                setCliente(null);
                dispatch(restoreMyClient());
                setClient(null);
              } else {
                const { id, label } = JSON.parse(newValue);
                setClient({ id, label });
                setCliente({ id, label });
              }
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Cliente" />}
          />
          <br />

          <Divider variant="middle" />
        </div> */}

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => {
            return (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === 2 ? (
                      <Typography variant="caption">Último paso</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  {
                    <step.body
                      cliente={formato?.client}
                      setCliente={setCliente}
                    />
                  }
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        disabled={index === steps.length - 2 ? !complete : null} //the one valid for steps   disabled={index === steps.length - 2 ? !complete : null}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? "Guardar" : "Continuar"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Regresar
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Alert severity="success">Todo completado!</Alert>
            <div style={{ display: "flex" }}>
              <Button
                onClick={handleReset}
                sx={{ mt: 1, mr: 1 }}
                variant="contained"
              >
                Restaurar
              </Button>
              <Link to="/dashboard/inspections/tray" style={styleLink}>
                <Button sx={{ mt: 1, ml: 1 }} variant="contained">
                  Ir a Bandeja de Inspecciones
                </Button>
              </Link>
            </div>
          </Paper>
        )}
      </Box>
      <SendModal
        handleClose={handleClose}
        open={open}
        setActiveStep={setActiveStep}
      />
    </>
  );
};

export default ConfigInspection;
