import ax from "../../helpers/axiosConfig";

import { encriptar } from "../../helpers/encrypt";

const EDI = process.env.REACT_APP_ENPOINT_EDI;

export const getMyClient = () => {
  return ax.get(`${EDI}/ADM/Cliente/ListarActivosxUsuario`);
};

export const getClient = () => {
  return ax.get(`${EDI}/ADM/Cliente/ListarUsuarioClientesPorIdUsuario`);
};

export const getInmueble = async (id) => {
  const { data: clientEncrypt } = await encriptar(id);
  const { data: nombreEncrypt } = await encriptar("");
  return ax.get(
    `${EDI}/INM/ListarActivosxClienteyUsuarioFiltro?pIdCliente=${clientEncrypt}&pNombre=${nombreEncrypt}`
  );
};

export const getProveedorFiltro = async (id) => {
  const { data: clientEncrypt } = await encriptar(id);
  const { data: nombreEncrypt } = await encriptar("");

  return ax.get(
    `${EDI}/ADM/Proveedor/ListarxClienteFiltro?pIdCliente=${clientEncrypt}&pNombre=${nombreEncrypt}`
  );
};

export const getTecnicos = async (obj) => {
  const data = await encriptar(obj);

  return ax.post(`${EDI}/SEG/Usuario/ListarTecnicos`, data);
};

export const getInspectorFiltro = async (id) => {
  const { data: clientEncrypt } = await encriptar(id);
  const { data: nombreEncrypt } = await encriptar("");
  return ax.get(`/api/ConfigInspecciones/ListarInpectoresFM/${id}`);

  // return ax.get(
  //   `${EDI}/INS/ListarInspectorPorIdsClientes?pIdCliente=${clientEncrypt}&pNombre=${nombreEncrypt}`
  // );
};

export const searchInmueble = async (idCliente,IdInmueble) => {

  let data = await encriptar({
    IdCliente: idCliente,
    IdUsuario: 3647,
    IdInmueble: IdInmueble,
  });

  let res = await ax.post(`${EDI}/INM/ListarActivosEnFormatoArbol`, data);
  if (res.TipoResultado) {
    return res.Lista;
  }
  return null;
};
/*
var req_solicitud = {
  IdCliente: this.datosBasicosFormGroup.value.myControlCliente.id,//cliente
  IdSolicitante: this.datosBasicosFormGroup.value.myControlSolicitante.id,//el que genera la solicitud
  TelefonoContacto:"",
  NombreContactoAdicional: "",
  TelefonoContactoAdicional: "",
  IdTipoSolicitud: 1017,//buscar tipo de soliocitud de emergencia &//id 
  CentroCosto: "",
  IdInmueble: this.ControlInmuebleSeleccionado.IdInmueble,//s
  IdEdificio: this.ControlInmuebleSeleccionado.IdEdificio,//s
  IdNivel: this.ControlInmuebleSeleccionado.IdNivel,//s

  IdClasificacionProblema: "",
  IdTipoRiesgo: 0,
  NombreAmbiente: "",
  IdGrupoMantenimiento:544,//desa
  IdUnidadMantenimiento: 10760,//desa--
  DescripcionCorta: "observación al generar ticket",//
  DescripcionDetallada:"observación al generar ticket",//
  ListIdsEquipos: [],
  ListIdAprobadorRequerimiento:[],//
  Origen: 1,
  UsuarioLogin: this.datosEdi.Id,
  listAdjuntoCloud:[]
}
*/
/*
var response = await postGuardarSolicitud(req_solicitud);
if (response) {
  let msg = '';
  if (response.Codigo) {
    msg = response.Mensaje + '\n Tu ticket generado es el ' + response.Codigo;
    this.bootstrapNotifyBarService.notifySuccess(msg);
    setTimeout(() => {
      this.router.navigate(['/solicitud/bandejasolicitud'])
    }, 5010)
    //this.datosBasicosFormGroup.reset();
    //this.LimpiarRegistroSolicitud();
  } else {
    msg = response.Mensaje;
    this.bootstrapNotifyBarService.notifyWarning(msg);
  }
}
*/

export const postGuardarSolicitud = async (data) => {
  data = await encriptar(data);
  return ax.post(`${EDI}/SOL/Grabar`, data);
};
export const PostGenerateTicket = (data) => {
  return ax.post(`${EDI}/INS/Edificio/GenerarSolicitudTrabajo`, data);
};
