import {NavLink} from "react-router-dom";

import {
    ListItemButton,
    Collapse,
    ListItemIcon,
    ListItemText,
    List,
} from "@mui/material";

import {Cloud} from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";

const ArrayMenu = ({e, index, handleDrawerToggle}) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <div key={index + "inpecciones"}>
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        {e.nombre === "Inspecciones" ? <Cloud/> : <ContentPasteSearchIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={e.nombre}/>
                    {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {e.subMenu?.map((element, index) => (
                            <NavLink
                                to={element?.ruta}
                                key={index + "menu"}
                                style={{textDecoration: "auto", color: "inherit"}}
                                onClick={handleDrawerToggle}
                            >
                                {element?.nombre !== "Planificacion de inspeccion" ? (
                                    <ListItemButton sx={{pl: 4}}>
                                        <ListItemText primary={element?.nombre}/>
                                    </ListItemButton>
                                ) : null}
                            </NavLink>
                        ))}
                    </List>
                </Collapse>
            </div>
        </>
    );
};

export default ArrayMenu;
