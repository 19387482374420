import axios from "axios";

const EDI = process.env.REACT_APP_ENPOINT_EDI;

export const encriptar = async (data) => {
  let string;
  if (typeof data === "object") {
    string = JSON.stringify(data);
  } else {
    string = data.toString();
  }
  try {
    const res = await axios.post(`${EDI}/Usuario/Encriptar`, {
      Body: string,
      Token: "MIw3lILd1k4GwoAuelSGjwPVepY",
    });

    return res;
  } catch (err) {
    console.log(err);
  }
};
/*
  Encryptar(cadena: string): string {
    const converted = this.toBinary(cadena);
    const encoded = btoa(converted);
    return encoded;
  }
*/



