import ax from "../../helpers/axiosConfig";

export const getTechnicalFormatAsync = (data) => {
  const { id } = data;

  return ax.get(`/api/FormatoTecnico/ObtenerFormato/${id}`);
};

export const finishInspectAsync = (dataFormato) => {
  return ax.post(`/api/FormatoTecnico/finalizarInspeccion`, dataFormato);
};

export const putInitFormatAsync = (data) => {
  const { dataParams, idConfig } = data;

  return ax.post(`/api/FormatoTecnico/InicioTecnico`, {
    idConfig,
    fechaInicioReal: dataParams.date,
    estado: "ESTADO0000025.22", //en ejecución
  });
};

export const AnularInspeccion = async (data) => {
  const { idConfig } = data;
  return await ax.get(`/api/Inspecciones/Anular/${idConfig}`);
};

/*export const updateDataAsync = (data) => {
  const { idFormat, dataFormato, idFormatos } = data;

  return ax.put(`/api/core/ActualizarFormato/${idFormat}`, {
    ...dataFormato,
    idFormatos,
  });
};*/
//params   /idFormato and verify body for update data
