import {useDispatch, useSelector} from "react-redux";
import { Button, TextField, IconButton } from "@mui/material";
import GroupFormat from "../groupFormat/GroupFormat";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ModalClose from "../../../../components/modalClose/ModalClose";

import {
  addGroup,
  deleteSection,
  addNameSection,
} from "../../../../../store/formatReducer/formatoReducer.reducer";
import "./Format.css";
import { useState } from "react";
import { useEffect } from "react";

const Format = ({ indice, element }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const { grupos } = element;
  const [bolEdit, setBolEdit] = useState(true);
  const [nameSection, setNameSection] = useState(null);

  //const { formato } = useSelector((state) => state.formato);

  const handleClick = (indice) => {
    dispatch(
      addGroup({
        nombre: `Nuevo Grupo ${grupos.length + 1}`,
        parametros: [],
        indice,
      })
    );
  };
  //bolEdit
  const handleDelete = (grupos) => {
    dispatch(deleteSection({ indice, grupos }));
    setOpen(false);
  };

  const handleEdit = () => {
    setBolEdit(false);
  };
  const handleInspection = (e) => {
    setNameSection(e.target.value);
  };
  useEffect(() => {
    if (nameSection !== null) {
      dispatch(addNameSection({ nameSection, indiceSection: indice }));
    }
  }, [nameSection]);

  return (
    <>
      <div className="content-seccion_input">
        <input
          type="text"
          name="nameSection"
          value={element.nombre}
          disabled={bolEdit}
          className="input-section"
          onChange={handleInspection}
        />
        <IconButton
          aria-label="edit"
          size="small"
          className="content-icon_edit"
          onClick={handleEdit}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          aria-label="delete"
          size="small"
          className="content-icon_delete"
          onClick={handleOpen}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="content-format">
        <Button
          type="button"
          variant="contained"
          className="btn-add-group"
          onClick={() => handleClick(indice)}
        >
          <span className="sumCode">&#43;</span> Agregar Grupo
        </Button>

        {grupos &&
          grupos.map((grupo, i) => (
            <GroupFormat
              key={i + "group"}
              grupo={grupo}
              indice={i}
              indiceSection={indice}
            />
          ))}
      </div>
      <ModalClose
        handleDelete={() => handleDelete(grupos)}
        text="Sección"
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default Format;
