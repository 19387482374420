import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ModalClose.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

const ModalClose = ({ handleDelete, text, open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <span
            id="modal-modal-description"
            className="titleModal"
            component="h5"
          >
            <DeleteIcon style={{ color: "red", fontSize: "2rem" }} />
            Eliminar {text}
          </span>
          <p id="modal-modal-description" component="h5" className="textModal">
            ¿Estás seguro que deseas eliminar, {text}?
          </p>
          <div className="content-btn">
            <Button onClick={handleClose} variant="contained">
              No
            </Button>
            <Button color="error" onClick={handleDelete} variant="contained">
              Sí,Eliminar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalClose;
