import { MenuItem, Menu, Checkbox } from "@mui/material";
import SubMenu from "./components/subMenu/SubMenu";

import { dataList } from "../../../helpers/dataList";
import { useDispatch, useSelector } from "react-redux";
import {
  addField,
  deleteField,
  updateObservacion,
  updateCorrectivo,
  updateRequiereAdjuntos,
  updatesubCampo,
} from "../../../store/formatReducer/formatoReducer.reducer";

import PropMenu from "../propsMenu/PropMenu";
import MultiValues from "./components/multiValues/MultiValues";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};

const { texto, archivo } = dataList;

const MenuList = ({ anchorEl, setAnchorEl, open, handleClose, indice, id }) => {
  const { formato } = useSelector((state) => state.formato);

  const propiedades =
    formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
      .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo] ===
    undefined
      ? formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams]
      : formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
          .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo];

  const {
    observacion,
    requiereCorrectivo,
    requiereAdjuntos,
    fieldLabel,
    fieldPlaceholder,
    fieldMandatory,
    fieldEditable,
    fieldVisible,
    fieldColumn,
    fieldRow,
    fileDb,
  } = propiedades;

  const dispatch = useDispatch();
  const indiceSeccionFormato = indice.indiceSection;

  const inputDefault = {
    idParametro: 1,
    field_id: "texto",
    fieldLabel: "",
    fieldMandatory: false,
    fieldEditable: true,
    fieldVisible: true,
    fieldColumn: 0,
    fieldRow: 0,
    fieldPlaceholder: "Ingresa Texto SubCampo",
    fieldType: "text",
    fieldValue: "",
  };

  const handleButton = (el) => {
    if (el) {
      switch (el) {
        case "Númerico":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                indiceSubcampo: indice.indiceSubcampo,
                id,
                idParametro: 2,
                field_id: "Númerico",
                fileDb,
                fieldLabel: fieldLabel,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldPlaceholder: fieldPlaceholder,
                fieldType: "number",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
                requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;

        case "Fecha":
          dispatch(
            addField({
              indiceSection: indice.indiceSection,
              indiceGroup: indice.indiceGroup,
              indiceParams: indice.indiceParams,
              indiceSubcampo: indice.indiceSubcampo,
              field: {
                indiceSection: indice.indiceSection,
                indiceGroup: indice.indiceGroup,
                indiceParams: indice.indiceParams,
                indiceSubcampo: indice.indiceSubcampo,
                id,
                idParametro: 5,
                field_id: "fecha",
                fileDb,
                fieldLabel: fieldLabel,
                fieldMandatory: fieldMandatory,
                fieldEditable: fieldEditable,
                fieldVisible: fieldVisible,
                fieldColumn: fieldColumn,
                fieldRow: fieldRow,
                fieldPlaceholder: fieldPlaceholder,
                fieldType: "fecha",
                fieldValue: "",
                observacion,
                requiereCorrectivo,
                requiereAdjuntos,
                subCampos: [],
              },
            })
          );
          break;
      }
    }
    setAnchorEl(null);
  };
  const handleDelete = (id) => {
    dispatch(
      deleteField({
        indiceSection: indice.indiceSection,
        indiceGroup: indice.indiceGroup,
        indiceParams: indice.indiceParams,
        indiceSubcampo: indice.indiceSubcampo,
        id,
      })
    );
    setAnchorEl(null);
  };

  const handleObserver = (e) => {
    dispatch(
      updateObservacion({
        ...indice,
        observacion: e.target.checked,
      })
    );
  };
  const handleSubCampo = () => {
    dispatch(
      updatesubCampo({
        ...indice,
        subCampos: inputDefault,
      })
    );
  };

  const handleCorrectivo = (e) => {
    dispatch(
      updateCorrectivo({
        ...indice,
        requiereCorrectivo: e.target.checked,
      })
    );
  };
  const handleRequiereAdjunto = (e) => {
    dispatch(
      updateRequiereAdjuntos({
        ...indice,
        requiereAdjuntos: e.target.checked,
      })
    );
  };

  return (
    <>
      <Menu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        {...popoverPropiert}
      >
        <SubMenu
          listName={"Texto"}
          data={texto}
          indice={indice}
          paramsId={id}
        />
        <MenuItem>
          <Checkbox onChange={handleObserver} checked={observacion} />
          Observaciones
        </MenuItem>
        <MenuItem>
          <Checkbox
            onChange={handleRequiereAdjunto}
            checked={requiereAdjuntos}
          />
          Se requiere Adjuntos
        </MenuItem>
        <MenuItem>
          <Checkbox onChange={handleCorrectivo} checked={requiereCorrectivo} />
          Correctivo
        </MenuItem>
        <MenuItem onClick={() => handleButton("Númerico")}>Númerico</MenuItem>
        <MenuItem onClick={() => handleButton("Fecha")}>Fecha</MenuItem>
        <MenuItem onClick={() => handleSubCampo()}>SubCampo</MenuItem>
        <MultiValues listName={"Varios Valores"} indice={indice} id={id} />

        <SubMenu
          listName={"Archivo"}
          data={archivo}
          indice={indice}
          paramsId={id}
        />

        <PropMenu listName={"Propiedades"} indice={indice} id={id} />

        <MenuItem onClick={() => handleDelete(id)} style={{ color: "red" }}>
          Eliminar
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuList;
