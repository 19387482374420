import { Box, Paper, Typography, TextField, Grid, Button } from "@mui/material";

import "moment/locale/es";
import moment from "moment";
import { postReport } from "../../../api/report";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import ProgressLoading from "../../../components/progressLoading/ProgressLoading";
function Reports() {
  const [initFecha, setInitFecha] = useState(null);
  const [finFecha, setFinFecha] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const handleSubmit = async () => {
    const initString = "00:00:00.000";
    const finString = "23:59:59.999";
    let initFechaDate = initFecha.format("YYYY-MM-DD");
    let finFechaDate = finFecha.format("YYYY-MM-DD");

    //.toString()+" "+finString;

    if (initFechaDate > finFechaDate) {
      alert("La fecha inicial debe ser menor que la fecha final");
      return;
    }

    /*if (initFechaDate===finFechaDate){
      finFechaDate=finFecha.add(1,"days").format("YYYY-MM-DD")
    }*/

    const usuarioSession = JSON.parse(localStorage.getItem("jsonCliente"));
    setLoading(true);
    const file = await postReport({
      FechaInicio: initFechaDate.toString() + " " + initString,
      FechaFin: finFechaDate.toString() + " " + finString,
      IdCliente: usuarioSession.id,
    });

    const url = window.URL.createObjectURL(new Blob([file]));

    const link = document.createElement("a");
    let date = new Date().getMilliseconds();
    link.download = `Inspección${date}.xlsx`;
    link.href = url;
    link.click();
    document.body.appendChild(link);
    window.URL.revokeObjectURL(url);
    setLoading(false);
    return file;
  };

  return (
    <>
      {loading ? (
        <ProgressLoading />
      ) : (
        <Paper>
          <Box px={3} py={2}>
            <Typography variant="h6" align="left" margin="dense" m={5}>
              GENERAR REPORTE
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={moment.locale()}
                >
                  <Stack spacing={3}>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      disableFuture
                      label="Fecha inicial"
                      openTo="year"
                      views={["year", "month", "day"]}
                      value={initFecha}
                      onChange={(newValue) => setInitFecha(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} sm={6}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={moment.locale()}
                >
                  <Stack spacing={3}>
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      //disableFuture
                      label="Fecha final"
                      openTo="year"
                      views={["year", "month", "day"]}
                      value={finFecha}
                      onChange={(newValue) => setFinFecha(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => await handleSubmit()}
              >
                Generar
              </Button>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
}

export default Reports;
