import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addCliente,
  addInmueble,
  addStartDate,
  addStartTime,
  addProveedor,
  addPlantilla,
  addTecnicos,
  clearRegisterInspection,
  addInspectoresFm,
} from "../../../../../store/registerInspectReducer/registerInspectReducer.reducer";
import { getInmueble, getClient } from "../../../../../api/ediServices";
//import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  FormControl,
  FormHelperText,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import moment from "moment";

const Period = ({ cliente, setCliente }) => {
  const [clientes, setClientes] = useState([]);
  const [clientedefault, setClientedefault] = useState();
  const [inmuebles, setInmuebles] = useState(null);
  const [clienteSelected, setClienteSelected] = useState(false);
  const [inmuebleSelected, setInmuebleSelected] = useState(false);

  const [loading, setLoading] = useState(false);
  const { myClient, RegisterData } = useSelector(
    (state) => state.registerInspect
  );

  const dispatch = useDispatch();

  const validateParametrs = () => {
    if (!RegisterData?.cliente) {
      setClienteSelected(true);
    }
    if (!RegisterData?.inmueble.length !== 0) {
      setInmuebleSelected(true);
    }
  };
  const getInmuebleAsync = async (client) => {
    try {
      if (client) {
        setLoading(true);
        const response = await getInmueble(client);
        const { ListaInmueble } = await JSON.parse(response);

        setInmuebles(ListaInmueble);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDate = (e) => {
    dispatch(addStartDate(e));
  };
  const handleTime = (e) => {
    dispatch(addStartTime(e));
  };

  /*const ListarOpcionPorIdUsuarioAsync = async () => {
    const res = await ListarOpcionPorIdUsuario();
    console.log(JSON.parse(res));
  };*/

  const getClientAsync = async () => {
    const res = await getClient();
    const { ListaEntidadComun } = JSON.parse(res);
    setClientes(ListaEntidadComun);
  };
  useEffect(() => {
    dispatch(clearRegisterInspection());
    /*if (clientes === null) {
      //ListarOpcionPorIdUsuarioAsync();
    }*/

    setClientes([]);
    getClientAsync();

    if (RegisterData?.cliente === null) {
    }

    if (RegisterData?.inmueble !== null && inmuebles !== null) {
      getInmuebleAsync(RegisterData?.cliente?.id);
    }

    if (localStorage.getItem("jsonCliente") && inmuebles === null) {
      let localStorageData = JSON.parse(localStorage.getItem("jsonCliente"));
      setClienteSelected(false);

      setCliente(localStorageData);
      dispatch(addCliente(localStorageData));

      getInmuebleAsync(localStorageData.id);
    }
  }, [cliente]);

  const setValueControl = () => {
    if (clientes.length > 0) {
      let { id } = JSON.parse(localStorage.getItem("jsonCliente"));

      let clienteFiltrados = clientes.find((x) => {
        return x.Id === id;
      });

      const dataTransform = {
        id: clienteFiltrados.Id,
        label: clienteFiltrados.Nombre,
      };

      return JSON.stringify(RegisterData?.cliente) === "null"
        ? JSON.stringify(dataTransform)
        : JSON.stringify(RegisterData?.cliente);
    }
  };

  return (
    <div>
      <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
        <Autocomplete
          labelId="clienteInspection"
          fullWidth
          value={setValueControl() !== undefined ? setValueControl() : null}
          options={clientes.map((e, index) =>
            JSON.stringify({ label: e.Nombre, id: e.Id })
          )}
          getOptionLabel={(option) => {
            let data = JSON.parse(option);
            //  let labelName= data?.label.split("-");

            return data?.label;
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              sx={{ maxHeight: "10rem" }}
              error={clienteSelected}
              onBlur={validateParametrs}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label="Cliente"
            />
          )}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              const { label, id } = JSON.parse(newValue);
              setClienteSelected(false);
              setCliente((newValue) => newValue);
              dispatch(addCliente({ label, id }));
              getInmuebleAsync(id);
              dispatch(addInmueble([]));
              dispatch(addProveedor(null));
              dispatch(addInspectoresFm([]));
            }
          }}
        />
        {clienteSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>

      <br />

      <FormControl fullWidth sx={{ marginTop: "0.8rem" }}>
        <Autocomplete
          multiple={true}
          fullWidth
          disabled={setValueControl() !== undefined ? false : true}
          value={
            RegisterData?.inmueble.length !== 0
              ? (RegisterData?.inmueble).map((e) => JSON.stringify(e))
              : []
          }
          options={
            inmuebles
              ? inmuebles?.map((e) =>
                  JSON.stringify({ label: e.Nombre, id: e.Id })
                )
              : []
          }
          getOptionLabel={(option) => {
            let data = JSON.parse(option);
            return data.label;
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              label="Inmueble"
              {...params}
              error={inmuebleSelected}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setInmuebleSelected(false);

              dispatch(addInmueble(newValue));
            }
          }}
        />
        {inmuebleSelected && (
          <FormHelperText sx={{ color: "red" }}>
            Este Campo es Obligatorio
          </FormHelperText>
        )}
      </FormControl>

      <br />
      <div>
        <div>Fecha y Hora de inicio </div>
        <br />
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Fecha Inicio"
              value={RegisterData?.startDate}
              onChange={handleDate}
              minDate={moment()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <br />
          <br />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              label="Hora Inicio"
              value={RegisterData?.startTime}
              onChange={handleTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default Period;
