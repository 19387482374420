import { createSlice } from "@reduxjs/toolkit";
import { getConfigInspection } from "./ConfigInspectionReducer.action";

const initialState = {
  confingInspection: null,
  errorConfig: null,
  loadingConfig: false,
  success: false,
  message: null,
};

export const configInspectionReducer = createSlice({
  name: "configInspectionReducer",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.message = null;
    },
    clearErrorAction: (state) => {
      state.errorConfig = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigInspection.pending, (state) => {
        state.loadingConfig = true;
      })
      .addCase(getConfigInspection.fulfilled, (state, action) => {
        state.loadingConfig = false;

        state.confingInspection = action.payload.body;
      })
      .addCase(getConfigInspection.rejected, (state, action) => {
        state.loadingConfig = false;
        state.errorConfig = action.error.message;
      });
  },
});

export default configInspectionReducer.reducer;
