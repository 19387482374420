import { useState } from "react";
import { useDispatch } from "react-redux";
import MenuList from "../../../../components/menuList/MenuList";
import Element from "../../../../components/element/Element";
import "./ButtonList.css";
import TuneIcon from "@mui/icons-material/Tune";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IconButton, TextField, Checkbox } from "@mui/material";

import { updateTextObserver } from "../../../../../store/formatReducer/formatoReducer.reducer";

const ButtonList = ({ field, indice }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { observacion, textoObservacion,requiereCorrectivo,requiereAdjuntos } = field;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    dispatch(
      updateTextObserver({ ...indice, textoObservacion: e.target.value })
    );
  };

  return (
    <div className="content-btnList">
      <Element field={field} indice={indice} />

      <div className="content-btnList__button">
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <ControlPointIcon />
        </IconButton>
        <MenuList
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          indice={indice}
          id={field.id}
          setAnchorEl={setAnchorEl}
        />
      </div>
      {observacion && (
        <div className="buttonList-observer">
          <TextField
            variant={"outlined"}
            size="large"
            label="Observación"
            value={textoObservacion}
            multiline
            onChange={handleChange}
            rows={4}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      )}
      {requiereCorrectivo && (
        <div className="buttonList-observer ms-2">
          <Checkbox  checked={requiereCorrectivo} />
          Correctivo
        </div>
      )}
      {requiereAdjuntos && (
        <div className="buttonList-observer ms-2">
          <AttachFileIcon></AttachFileIcon> Adjuntos
        </div>
      )}
      
    </div>
  );
};

export default ButtonList;
