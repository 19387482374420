export const DBConfig = {
  name: "inspeccionesdb",
  version: 1,
  objectStoresMeta: [
    {
      store: "Menus",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "menu", keypath: "menu", options: { unique: false } },
        { name: "idUsuario", keypath: "idUsuario", options: { unique: false } },
        { name: "estado", keypath: "estado", options: { unique: false } },
        { name: "activo", keypath: "activo", options: { unique: false } },
        { name: "offline", keypath: "offline", options: { unique: false } },
      ],
    },
    {
      store: "Usuarios",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "idUsuario", keypath: "idUsuario", options: { unique: false } },
        {
          name: "accessEdi",
          keypath: "accessEdi",
          options: { unique: false },
        },
        {
          name: "accesToken",
          keypath: "accesToken",
          options: { unique: false },
        },
        { name: "datos", keypath: "datos", options: { unique: false } },
        { name: "estado", keypath: "estado", options: { unique: false } },
        { name: "activo", keypath: "activo", options: { unique: false } },
        { name: "offline", keypath: "offline", options: { unique: false } },
      ],
    },
    {
      store: "trayInspections",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        {
          name: "inspection",
          keypath: "inspection",
          options: { unique: false },
        },
        { name: "idUsuario", keypath: "idUsuario", options: { unique: false } },
        { name: "idConfig", keypath: "idConfig", options: { unique: false } },
      ],
    },
    {
      store: "technicalFormatos",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "formato", keypath: "formato", options: { unique: false } },
        { name: "idUsuario", keypath: "idUsuario", options: { unique: false } },
        { name: "idConfig", keypath: "idConfig", options: { unique: false } },
      ],
    },
    {
      store: "inmuebles",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "inmueble", keypath: "inmueble", options: { unique: false } },
        { name: "idUsuario", keypath: "idUsuario", options: { unique: false } },
        { name: "idConfig", keypath: "idConfig", options: { unique: false } },
        { name: "idCliente", keypath: "idCliente", options: { unique: false } },
      ],
    },
    {
      store: "menuGroups",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "idConfig", keypath: "idConfig", options: { unique: false } },
        { name: "campo", keypath: "campo", options: { unique: false } },
        { name: "registro", keypath: "registro", options: { unique: false } },
      ],
    },
  ],
};
