import { createSlice } from "@reduxjs/toolkit";
import { getTemplate } from "./trayInspectionReducer.action";

const initialState = {
  template: [],
  error: null,
  loading: false,
  success: false,
  message: null,
};

export const trayInspectionReducer = createSlice({
  name: "trayInspectionReducer",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.message = null;
    },
    clearErrorAction: (state) => {
      state.error = null;
    },
    addTemplateOffline: (state, action) => {
      state.template=[]
      action.payload.forEach((item) => {
        state.template.push(item.inspection);
      });
     
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.loading = false;
  
        state.template = action.payload;
 
      })
      .addCase(getTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { addTemplateOffline } = trayInspectionReducer.actions;
export default trayInspectionReducer.reducer;
