import { Outlet, useParams, useNavigate } from "react-router-dom";

import { useIndexedDB } from "@slnsw/react-indexed-db";
import { useState, useEffect, useRef } from "react";
import { clearUser } from "../store/authReducer/authReducer.reducer";
import { isExpired, decodeToken } from "react-jwt";
import OfflineSwitch from "./components/offlineSwitch/OfflineSwitch";
import MyClient from "./components/myClient/MyClient";

import { UserCircle as UserCircleIcon } from "../icons/user-circle";
import { getLoginAsync } from "../api/auth";
import fromBinary from "../helpers/fromBinary";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  offlineState,
} from "../store/authReducer/authReducer.reducer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";

import tgestiona from "../img/tgestiona.png";
import edificiologo from "../img/edificiologo.png";
import {
  Box,
  Drawer,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Typography,
  List,
  Toolbar,
  Avatar,
  CssBaseline,
  ListSubheader,
  Grid,
} from "@mui/material";
import { DrawerHeader } from "./components/dashboardElement/DashboardElement";
import AppBar from "@mui/material/AppBar";
import "./Dashboard.css";
import AccountPopover from "./AccountPopover";
import ArrayMenu from "./components/arrayMenu/ArrayMenu";

import useNetwork from "../hooks/useNetwork/UseNetwork";

const Dashboard = (props) => {
  const { window: windowProp } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { getByIndex: getByIndexMenu, add: addMenu } = useIndexedDB("Menus");
  const { getByIndex: getByIndexUser, add: addUser1 } =
    useIndexedDB("Usuarios");
  const [data, setData] = useState(null);
  const [cambioToken] = useState(false);
  const networkState = useNetwork();
  const { online } = networkState;
  const settingsRef = useRef(null);
  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  const { user, offlineStore } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  let myDecodedToken = null;
  let isMyTokenExpired = null;
  let localToken = localStorage.getItem("accessToken");
  useEffect(() => {
    localToken = localStorage.getItem("accessToken");

    if (localToken !== null) {
      myDecodedToken = decodeToken(localToken);
      isMyTokenExpired = isExpired(localToken);
      
    }
    if (
      myDecodedToken === null ||
      isMyTokenExpired === true ||
      myDecodedToken === undefined
    ) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accessEdi");
      dispatch(clearUser());
      navigate("/tokenAspired");
    }
    if (data === null && myDecodedToken !== null) {
      const user = JSON.parse(myDecodedToken?.UserData);

      dispatch(addUser(user));
      dispatch(offlineState(false));
    }
  }, [cambioToken]);
  if (id && id.length > 20) {
    const decoded = atob(id);
    const original = fromBinary(decoded);
    const datosEdi = JSON.parse(original);

    localStorage.setItem("accessEdi", JSON.stringify(datosEdi.usuario));
    localStorage.setItem("accessToken", datosEdi.token);
  }

  function handleLogout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessEdi");
    dispatch(clearUser());
    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}`;
  }

  useEffect(() => {
    if (user?.countHourMinute) {
      (async () => {
        const accessEdi = JSON.parse(localStorage.getItem("accessEdi"));
        const accessToken = localStorage.getItem("accessToken");
        const userOffline = await getByIndexUser(
          "idUsuario",
          user?.countHourMinute
        );
        let menuOffline = data;
        menuOffline = data.map((item) => {
          const newItem = { ...item };
          if (newItem.id === 9124) {
            let objNew = [...newItem.subMenu].find((item) => {
              const newEl = { ...item };
              if (newEl.id === 9131) {
                return newEl;
              }
            });

            newItem.subMenu = [objNew];
          }
          return newItem;
        });

        if (userOffline === undefined) {
          await addMenu({
            menu: menuOffline,
            idUsuario: user?.countHourMinute,
            estado: 1,
            activo: true,
            offline: false,
          });
          await addUser1({
            idUsuario: user?.countHourMinute,
            accessEdi: accessEdi,
            accesToken: accessToken,
            datos: user,
            estado: 1,
            activo: true,
            offline: false,
          });
        }
      })();
    }
  }, [data]);

  useEffect(() => {
    if (user?.countHourMinute) {
      if (offlineStore === true || !online) {
        (async () => {
          const { menu } = await getByIndexMenu(
            "idUsuario",
            user?.countHourMinute
          );

          setData(menu);
          const { offline } = await getByIndexUser(
            "idUsuario",
            user?.countHourMinute
          );

          navigate("/dashboard/inspections/tray");
          dispatch(offlineState(offline));
        })();
      }
      if (offlineStore === false && online) {
        (async () => {
          const { body } = await getLoginAsync({
            IdUser: user?.countHourMinute,
          });

          setData(body);
          const { offline } = await getByIndexUser(
            "idUsuario",
            user?.countHourMinute
          );

          dispatch(offlineState(offline));
        })();
      }
    }
  }, [offlineStore, user?.countHourMinute, online]);

  const drawerWidth = 240;

  const container =
    windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#fff",
        }}
      >
      
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
          className="dashboard-appBar"
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge={"end"}
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>
          
          
          <p
            className={"Appbar-title"}
            style={{ flexGrow: 1, color: "#1976d2" }}
          >
          </p>
          <MyClient></MyClient>
          
          <OfflineSwitch menu={data} />
          {user?.countHourMinute && (
            <>
              <Avatar
                onClick={() => setOpenAccountPopover(true)}
                ref={settingsRef}
                sx={{
                  cursor: "pointer",
                  height: 40,
                  width: 40,
                  ml: 1,
                }}
                src="/static/images/avatars/avatar_1.png"
              >
                <UserCircleIcon fontSize="small" />
              </Avatar>
              <AccountPopover
                anchorEl={settingsRef.current}
                open={openAccountPopover}
                onClose={() => setOpenAccountPopover(false)}
              />
            </>
          )}
          
        </Toolbar>
      
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box sx={{ width: drawerWidth }}>
            <center>
            <div className="auth-form__card--logo">
              <figure className="logo">
              <img src={tgestiona} alt={"Tgestiona"} style={{ width: 100 }} />
                <img src={edificiologo} alt={"edificiologo"} style={{ width: 100 }} />
              </figure>
            </div>
              {/* <Typography variant="button" display="block" gutterBottom>
                checklist de inspecciones
              </Typography> */}
              <h3> CHECKLIST DE INSPECCIONES </h3>
            </center>
            <List>
              <Divider />
              {data?.map((e, index) => (
                <ArrayMenu
                  e={e}
                  index={index}
                  handleDrawerToggle={handleDrawerToggle}
                  key={index}
                />
              ))}
            </List>
            <Divider />
            <List>
              <ListItemButton
                sx={{
                  color: "red",
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    color: "red",
                  }}
                >
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Salir" />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Box sx={{ width: drawerWidth }}>
            <center>
            <div className="auth-form__card--logo">
              <figure className="logo">
              <img src={tgestiona} alt={"Tgestiona"} style={{ width: 100 }} />
                <img src={edificiologo} alt={"edificiologo"} style={{ width: 100 }} />
              </figure>
            </div>
              {/* <Typography variant="button" display="block" gutterBottom>
                checklist de inspecciones
              </Typography> */}
              <h3> CHECKLIST DE INSPECCIONES </h3>
            </center>
            <List>
              <Divider />
              {data?.map((e, index) => (
                <ArrayMenu e={e} index={index} key={index} />
              ))}
            </List>
            <Divider />
            <List>
              <ListItemButton
                sx={{
                  color: "red",
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    color: "red",
                  }}
                >
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Salir" />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,

          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        className="dashboard-main"
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
