import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePropPosition } from "../../../../../store/formatReducer/formatoReducer.reducer";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuItem, Menu, TextField, Button } from "@mui/material";

const popoverPropiert = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "left",
  },
};

const PropPosition = ({ indice }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [row, setRow] = useState();
  const [col, setCol] = useState();
  const { formato } = useSelector((state) => state.formato);
  const dispatch = useDispatch();

  let propiedades =
  indice.indiceSubcampo === undefined
    ? formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
        .parametros[indice.indiceParams]
    : formato.secciones[indice.indiceSection].grupos[indice.indiceGroup]
        .parametros[indice.indiceParams].subCampos[indice.indiceSubcampo];

  const { fieldColumn, fieldRow } = propiedades;


  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSend = () => {
    dispatch(
      updatePropPosition({ ...indice, fieldRow: row, fieldColumn: col })
    );
    setAnchorEl(null);
  };

  const onKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <MenuItem
        aria-controls={open ? "basic-menu1" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ width: "none", minWidth: "none" }}
      >
        Posición
        {open ? <ArrowRightIcon /> : <ArrowDropUpIcon />}
      </MenuItem>
      <Menu
        id="basic-menu1"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        {...popoverPropiert}
      >
        <div
          style={{ margin: "1.2rem", display: "flex", flexDirection: "column" }}
        >
          <TextField
            key={"fieldRow"}
            id={"fieldRow"}
            label="Fila"
            InputLabelProps={{ shrink: true }}
            size="small"
            defaultValue={fieldRow}
            variant={"standard"}
            type="number"
            onChange={(e) => {
              let rowInt = parseInt(e.target.value);
              return setRow(rowInt);
            }}
            onKeyDown={onKeyDown}
          />
          <TextField
            key={"fieldColumn"}
            id={"fieldColumn"}
            style={{ marginTop: "0.5rem" }}
            label="Columna"
            InputLabelProps={{ shrink: true }}
            size="small"
            defaultValue={fieldColumn}
            variant={"standard"}
            type="number"
            onChange={(e) => {
              let colInt = parseInt(e.target.value);
              return setCol(colInt);
            }}
            onKeyDown={onKeyDown}
          />
          <Button
            type="button"
            size="small"
            variant="contained"
            style={{ margin: "0.8rem" }}
            onClick={handleSend}
          >
            Guardar
          </Button>
        </div>
      </Menu>
    </>
  );
};

export default PropPosition;
